@import '../../../../shared/styles/colors.scss';

.wrapper {
  position: relative;
}

.share {
  position: absolute;
  top: 0;
  right: -24px;
  transform: translateX(100%);
}

.info {
  &__row {
    display: flex;
    column-gap: 8px;
    align-items: center;
    justify-content: start;
    margin-top: 8px;
    flex-wrap: wrap;
  }
}

.firstRow {
  display: flex;
  justify-content: space-between;
}

.descr {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  margin-top: 16px;
}

.divider {
  width: 4px;
  height: 4px;
  border-radius: 100px;
  background: $purple_main;
  opacity: 0.3;
}

.plate {
  margin-top: 16px;
}

.descr__wrapper {
  display: flex;
  align-items: center;
  margin-top: 16px;
  column-gap: 30px;
}

.btn__unwrap {
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    .text__unwrap {
      color: $purple_main_hover;
    }
    .arrow__unwrap {
      span {
        &::before {
          color: $purple_main_hover;
        }
      }
    }
  }
}

.text__unwrap {
  color: $purple_main;
}

.arrow__unwrap {
  padding: 7px 0px;
  transform: rotate(90deg);
  scale: 0.65;
}

.arrow__wrapper {
  display: flex;
  gap: 2px;
  cursor: pointer;
  &:hover {
    .arrow__text {
      color: $purple_main_hover;
    }
    .arrow {
      span {
        &::before {
          color: $purple_main_hover;
        }
      }
    }
  }
}

.arrow_up {
  transform: rotate(270deg) translateX(0px) translateY(7px);
  span {
    &::before {
      font-size: 10px;
    }
  }
}
.arrow_down {
  transform: rotate(90deg) translateX(0px);
  span {
    &::before {
      font-size: 10px;
    }
  }
}

.arrow__wrapper {
  display: flex;
  gap: 2px;
  cursor: pointer;
  &:hover {
    .arrow__text {
      color: $purple_main_hover;
    }
    .arrow {
      span {
        &::before {
          color: $purple_main_hover;
        }
      }
    }
  }
}

.arrow {
  transform: rotate(90deg);
  span {
    &::before {
      font-size: 10px;
    }
  }
}

@media (max-width: 1040px) {
  .descr {
    justify-content: start;
    column-gap: 16px;
  }
}

@media (max-width: 800px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    column-gap: 16px;
    border-radius: 0px 0px 16px 16px;
    background: $white_main;
    padding: 16px 16px 24px 16px;
  }
  .descr {
    justify-content: center;
    row-gap: 8px;
    flex-wrap: wrap;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }

  .info {
    &__row {
      justify-content: center;
      text-align: center;
    }
  }
  .plate {
    margin-top: 8px;
  }
}

@media (max-width: 1200px) {
  .share {
    right: 32px;
  }
}
