@import '../../styles/colors.scss';

.paragraph {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.small {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.tiny {
  font-size: 9px;
  font-weight: 400;
  line-height: 11px;
}

.mobile-small {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.noLink {
  text-decoration: none;
  color: $black_main;
}

.bold {
  font-weight: 600;
}

.regular {
  font-weight: 400;
}

.purple-main {
  color: $purple_main;
}

.red {
  color: #ff0000;
}

.white-main {
  color: $white_main;
}

.black-main {
  color: $black_main;
}

.secondary-black {
  color: $secondary_black;
}

.grey {
  color: $grey;
}
.red {
  color: #ff0000;
}

.error {
  color: rgba(255, 0, 0);
}
