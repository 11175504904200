@import '../../shared/styles/colors.scss';
@import '../../shared/styles/mixins.scss';
.page{
	padding-top: 64px;
	padding-bottom: 160px;
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	gap: 40px;
}
.header {
  display: grid;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  &__title {
    display: flex;
    gap: 16px;
    color: #cccccc;
    align-items: flex-end;
  }
  &__subtitle {
    transform: translateY(-3px);
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
}
.progressBar {
	background-color: $purple_fill;
	height: 2px;
	border-radius: 10px;
}
.progressLine {
	height: 2px;
	background-color: $purple_main;
	transition: 0.2s all;
	border-radius: 10px;
}
.btnToMainPage {
	min-width: 111px;
}
.wrapper {
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 60px;
}
.stepsWrapper {
	width: 366px;
}
.nextButton {
	padding: 12px 64px;
	width: 226px;
	border-radius: 8px;
	background-color: $purple_main;
	transition: 0.2s all;
	&:hover {
	  background-color: $purple_main_hover;
	  transition: 0.2s all;
	}
}
.backButton {
	padding: 8px 16px;
	border-radius: 8px;
	background: $white_main;
	border: 1px solid $purple_main;
	color: $purple_main;
	width: 72px;
  }
@media (max-width: 1040px) {
	.wrapper {
	  width: 100%;
	  display: flex;
	  flex-direction: column-reverse;
	  align-items: center;
	  gap: 30px;
	}
}
@media (max-width: 576px) {
	.slash {
		display: none;
	}
	.nextButton {
		width: 288px;
	  }
	.header__title {
		justify-content: space-between;
	}
}
@media (max-width: 410px) {
	.stepsWrapper {
	  width: 100%;
	}
	// нижних двух пока что нет
	.submitButton {
		width: 100% !important;
		padding: 12px 60px;
	}
	.nextButton {
	  width: 100%;
	}
  }