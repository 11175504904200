@import '../../../../shared//styles/colors.scss';
@import '../../../../shared//styles/mixins.scss';

.card {
  width: 660px;
  @include card_3D;
  padding: 24px 48px 24px 24px;
  border-radius: 16px;
  &__header {
    margin-top: 12px;
  }
}

.geo {
  &__objects {
    display: flex;
    justify-content: start;
    column-gap: 12px;
    margin-top: 8px;
  }
  &__object {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
  &__icon {
    width: 18px;
    height: 16px;
    background-image: url('../../../../assets//icons/geo_objects/geo-object.svg');
    background-repeat: no-repeat;
  }
}

.divider {
  opacity: 0.5;
  border: 1px solid #cccccc;
  width: 100%;
  margin: 16px 0px;
}
.last__row {
  display: flex;
  align-items: center;
  justify-content: start;

  background: linear-gradient(
    270deg,
    $purple_fill 42.79%,
    rgba(244, 244, 246, 0) 100%
  );
  border-radius: 8px;
  padding: 0px 8px 0px 0px;
}
.smoke__alchocol {
  display: flex;
  align-items: center;
}
.icon__smoke {
  display: block;
}
.icon__alchocol {
  display: block;
  margin-left: 6px;
}
.text {
  margin-left: 11px;
}
.preferences__neighbours {
  display: flex;
  align-items: center;

  padding: 0px 8px 0px 12px;
}
.icon {
  width: 8px;
  height: 8px;
  border-radius: 2px;
  background: $purple_main;
  transform: rotate(45deg);
}
.text_preferences {
  margin-left: 8px;
}

@media (max-width: 1040px) {
  .card {
    width: 100%;
    background: $white_main;
  }
}

@media (max-width: 800px) {
  .card {
    box-shadow: 0px 8px 0px 0px $purple_main;
    &__header {
      font-size: 20px;
    }
  }

  .geo {
    &__objects {
      flex-wrap: wrap;
      row-gap: 4px;
    }
  }
  .last__row {
    background: $purple_fill;
    border-radius: 8px;
    flex-wrap: wrap;
    column-gap: 12px;
    row-gap: 8px;
    padding: 8px;
  }

  .preferences__neighbours {
    padding: 0;
  }
  .text {
    margin-left: 8px;
  }
}
