@import '../../../../shared/styles/colors.scss';
@import '../../../../shared/styles/mixins.scss';

.card {
  max-width: 660px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @include card_3D;
  padding: 24px 38px 24px 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  &__info {
    display: flex;
    gap: 12px;
  }
  &__dots {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
  }
  &__preferences {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  &__icons {
    display: flex;
    font-display: row;
    gap: 8px;
    transform: translateY(1px);
  }
  &__preference {
    display: flex;
    gap: 11px;
    align-items: center;
    transform: translateY(2px);
  }
}

.divider {
  width: 4px;
  height: 4px;
  border-radius: 100px;
  background: $purple_main;
  opacity: 0.3;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.avatar {
  width: 44px;
  height: 44px;
}

.divider__horisontal {
  width: 100%;
  opacity: 0.5;
  border: 1px solid #cccccc;
}

.last__row {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}

.habbits {
  display: flex;
  column-gap: 11px;
}

.icons {
  display: flex;
  column-gap: 6.5px;
}

.desired__neighbour {
  display: flex;
  column-gap: 8px;
}

.no__underline {
  text-decoration: none;
}

.datePublish {
}

@media (max-width: 1040px) {
  .card {
    max-width: 100%;
  }
}

@media (max-width: 800px) {
  .card {
    padding: 16px;
    row-gap: 16px;
    border-radius: 16px;
    background: $white_main;
    box-shadow: 0px 8px 0px 0px $purple_main;
  }
  .wrapper {
    border-radius: 16px;
    background: $white_main;
  }
  .datePublish {
    text-align: center;
  }
  .last__row {
    display: flex;
    padding: 8px;
    align-items: flex-start;
    align-content: flex-start;

    align-self: stretch;
    flex-wrap: wrap;
    row-gap: 14px;
    border-radius: 12px;
    background: $purple_fill;
  }
}
