@mixin card_shadow {
  box-shadow: 0px 5.84789px 22.1511px rgba(133, 94, 152, 0.0500662),
    0px 5.84789px 22.1511px rgba(133, 94, 152, 0.0500662),
    0px 2.86674px 10.8589px rgba(133, 94, 152, 0.0399338),
    0px 1.13351px 4.29362px rgba(133, 94, 152, 0.0279017);
}

@mixin card_3D {
  box-shadow: -8px 8px 0px #7953c3, 0px 6px 20px rgba(133, 94, 152, 0.06),
    0px 4px 8px rgba(133, 94, 152, 0.08), 0px 2px 4px rgba(133, 94, 152, 0.08),
    0px 6px 20px rgba(133, 94, 152, 0.08), 0px 6px 20px rgba(133, 94, 152, 0.08),
    0px 4px 12px rgba(133, 94, 152, 0.06), 0px 2px 4px rgba(133, 94, 152, 0.06);
}

@mixin default_multiple_shadow {
  box-shadow: 0px 1.1335145235061646px 4.293615818023682px 0px rgba(133, 94, 152, 0.03),
  0px 2.866743326187134px 10.85887622833252px 0px rgba(133, 94, 152, 0.04),
  0px 5.8478875160217285px 22.15108871459961px 0px rgba(133, 94, 152, 0.05),
  0px 5.8478875160217285px 22.15108871459961px 0px rgba(133, 94, 152, 0.05);
}