.form {
	display: inline-flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
}
.triple_neighbours {
	padding: 8px 14px !important;
  }
.triple_wrapper{
	gap: 23px;
}

@media (max-width: 410px) {
	.triple_wrapper{
		gap: 2px;
	}
}