@import '../../../shared/styles/colors.scss';
@import '../../../shared/styles/mixins.scss';

.modal__wrapper {
  width: 100%;
  flex-direction: column;

  display: flex;
}

.contacts {
  width: 100%;
  background-color: $purple_fill;
  margin-top: 24px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border-radius: 24px;
}

.contact__item {
  display: flex;
  align-items: center;
  column-gap: 16px;
  padding: 8px;
  background-color: $white_main;
  border-radius: 12px;
}

.icon {
  z-index: 1;
}

.btn__copy {
  border: none;
  background-color: $white_main;
  cursor: pointer;
}

.btn__telegram {
  margin-top: 40px;
}

@media (max-width: 572px) {
  .contacts {
    margin-top: 16px;
  }
  .btn__copy {
    margin-left: 8px;
  }

  .contact__item {
    column-gap: 8px;
  }
  .btn__telegram {
    margin-top: 20px;
    height: 48px;
  }
}
