@import '../../../shared//styles/colors.scss';
@import '../../../shared/styles/mixins.scss';

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 40px;
  transition: all 1s;
  @include default_multiple_shadow;
  position: sticky;
  z-index: 10;
  top: 0;
  background: $white_main;
  &__cross{
    cursor: pointer;
  }
  &__container{
		position: sticky;
		z-index: 10;
		top: 0;
		background: $white_main;
	}
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__list {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: start;
    column-gap: 32px;
    width: 100%;
    max-width: 1040px;
  }
  &__li {
    cursor: pointer;
    text-decoration: none;
    &_active {
      .header__li {
        color: $purple_main_hover;
      }
    }
    &:hover {
      color: $purple_main_hover;
    }
  }
  &__side {
    display: flex;
    align-items: center;
    justify-content: center;
    &_right {
      gap: 40px;
    }
  }
  &__link {
		padding: 16px 0px;
		background: $purple_main;
		display: flex;
		gap: 40px;
		justify-content:center;
		align-items:center;
	}
	&__btn{
		display: flex;
		gap: 8px;
		align-items: center;
    &__wrapper{
      display: flex;
      align-items: center;
      gap: 20px;
    }
	}
	&__icon {
		background-color: #2AABEE;
	}
	&__title{
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;
		color: $white_main;
	}
  &__icon_wrapper{
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.text__decoration_no {
  text-decoration: none;
}

.avatar {
  width: 48px;
  height: 48px;
}

.icon {
  width: 40px;
  height: 40px;
}

@media (max-width: 1040px) {
  .header {
    min-height: 48px;
    max-width: 100%;
    margin: 0 auto;
    z-index: 10;
    position: sticky;
    top: 0;
    background: $white_main;
    padding: 0;
    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: none;
      padding: 8px 0px;
    }
    &__link{
			flex-direction: column;
			gap: 8px;
			padding: 12px 0px;
		}
		&__title {
			order: 10;
      
		}
  }
}

@media (max-width: 800px) {
  .icon {
    width: 32px;
    height: 32px;
  }
  .header__btn{
    &__wrapper{
      gap: 5px;
    }
	}
}
