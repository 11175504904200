@import '../../../../shared/styles/colors.scss';
.mainWrapper {
  position: relative;
  &_allWidth {
    width: 100%;
  }
}

.plateWrapper {
  display: flex;
  align-items: center;
}
.sharePlate {
  position: absolute;

  transform: translateY(-25%);
  display: flex;
  z-index: 1;
  min-width: 320px;
  column-gap: 24px;
  height: 88px;
  padding: 16px;
  justify-content: space-between;
  align-items: flex-start;

  border-radius: 16px;
  background: $white_main;

  /* Default multiple shadows */
  box-shadow: 0px 1.13351px 4.29362px 0px rgba(133, 94, 152, 0.03),
    0px 2.86674px 10.85888px 0px rgba(133, 94, 152, 0.04),
    0px 5.84789px 22.15109px 0px rgba(133, 94, 152, 0.05),
    0px 5.84789px 22.15109px 0px rgba(133, 94, 152, 0.05);
}

.left {
  right: 42px;
}
.right {
  left: 42px;
}
.arrowForPlate {
  transform: rotate(-90deg);
}
.sharePlate__wrapper {
}

.socialAndCopyWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
}
.social {
  display: flex;
}
.urlCopy {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: $purple_fill;
  text-overflow: ellipsis;
}

.urlText {
  text-overflow: ellipsis;
}

@media (max-width: 1040px) {
  .sharePlate {
    position: static;
    transform: none;
    width: fit-content;
    // margin: 250px auto;
  }
  .plateWrapper {
    display: block;
    display: flex;
    justify-content: center;
    margin: 250px auto;
    max-width: 500px;
  }
}
