@import '../../../../shared/styles/mixins.scss';
.wrapper {
	border-radius: 8px;
	padding: 8px 16px;
	display: flex;
	gap: 40px;
	background-color: white;
	align-items: center;
	@include default_multiple_shadow;
}
.cancel {
	cursor: pointer;
}
