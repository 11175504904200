@import "../../../../shared/styles/colors.scss";
.wrapper {
  position: relative;
  display: inline-flex;
  flex-direction: column;
}
.input {
  width: 366px;
  padding: 16px 12px;
  min-height: 56px;
  border-radius: 8px;
  background-color: $purple_fill;
  font-family: Museo Sans Cyrl;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: 1px solid transparent;
  color: $black_main;
  outline: none;
  overflow-wrap: normal;
  &::-webkit-input-placeholder {
    color: #a1a6b1;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
.label {
  position: absolute;
  top:30px;
  user-select: none;
  transition: all 0.2s ease-in-out;
	cursor: text;
  &__wrapper{
    height:20px
  }
}
.error {
  position: absolute;
  bottom: -20px;
  right : 0;
  color: #ff0000;
  text-align: end;
  height:20px;
}
@media (max-width: 410px) {
  .wrapper {
    width: 100% !important;
  }
  .input {
    width: 100% !important;
  }
  .wrapper {
    width: 100% !important;
  }
}
