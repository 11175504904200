@import '../../../shared/styles/colors.scss';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
}

.avatar {
  border-radius: 10000px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

@keyframes skeleton {
  from {
    background: rgba(229, 229, 229, 0.2);
  }
  50% {
    background: rgba(229, 229, 229, 0.55);
  }
  to {
    background: rgba(229, 229, 229, 0.75);
  }
}

.skeleton__img {
  height: 40px;
  width: 40px;
  // background: $purple_fill;
  background: #afe1cd;
  border-radius: 24px;
  // animation: skeleton 1.5s linear infinite alternate;
}
