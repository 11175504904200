.room {
  margin-top: 64px;
  max-width: 768px;
  width: 100%;


}

.multiple {
  padding: 8px;
  height: 56px;
  width:100%;
  &__item {
    padding: 8px 30px;
  }
}

.tooltip{
  top:0px;
  right: -290px;
}

.wrapper {
  display: grid;
  gap: 24px;
  width: 100%;

  // grid-template-columns: repeat(1, 100%);
  margin-top: 56px;
}

.priceWrapper {
  width:100%;
  display: flex;
  align-items: start;
  gap: 12px;
  height: fit-content;
}
.inputWrapper{
  width:100%;
  height: 80px;
  display: flex;
  align-items: end;
  div{
    width:100%;
  }
}


.squareInputWrapper {
  width: 100%;
}

.price{
  width:100%;
}


.priceInput {
  width: 100%;
}

.checkboxes {
  margin-top: 15px;
  display: flex;
  gap: 20px 15px;
}

.imgInput {
  margin-top: 48px;
  position: relative;
}
.deposit {
  width:100%;

}

@media (max-width: 700px) {
  .inputWrapper {
    width: 288px;
  }

  .multiple {
    padding: 8px 18px;
    width: 100%;
    &__item {
      padding: 8px 10px;
    }
  }

  .room {
    margin-top: 40px;
  }

  .wrapper {
    display: block;
    width: 288px;
    margin-top: 40px;
  }

  .priceWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 35px 0;
  }

  .squareWrapper {
    width: 288px;
  }

  .price {
    margin-top: 35px;
  }

  .checkboxes {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .imgInput {
    margin-top: 40px;
  }
}
