@import '../../../shared/styles/colors.scss';

.wrapperModal {
  padding: 40px 80px;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: start;
}

.btnsGroup {
  display: flex;
  justify-content: space-between;
}

.btn {
  width: 152px;
}

@media (max-width: 572px) {
  .wrapperModal {
    padding: 40px;
  }
  .btn {
    width: 45%;
  }
}
