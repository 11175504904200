@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.overlay {
  background-color: rgba(4, 0, 19, 0.05);
  display: block;
  height: 100%;
  left: 0;
  overflow: scroll;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  backdrop-filter: blur(5px);
}

.modal {
  margin: 200px auto;
  max-width: 512px;

  background-color: $white_main;
  border-radius: 32px;
  min-height: 150px;
  overflow: hidden;
  padding: 40px;
  position: relative;
  @include card_shadow;

  backdrop-filter: blur(5px);
}

.close {
  cursor: pointer;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 38px;
  top: 38px;
  width: 34px;
  &::before {
    content: '';
    position: absolute;
    background: $secondary_black;
    border-radius: 50px;
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    right: 0;
    top: 0;
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  }

  &:hover {
    &::before {
      opacity: 0.1;
    }
  }
}

@media (max-width: 1024px) {
  .modal {
    margin: 100px auto;
  }
}

@media (max-width: 572px) {
  .modal {
    padding: 16px;
    margin: 200px 16px;
  }
  .close {
    top: 20px;
    right: 14px;
  }
}