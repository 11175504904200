.item {
  padding: 8px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
}

.item:hover {
  border-radius: 8px;
  cursor: pointer;
  background-color: #EEEEF1;
}

.active {
  background-color: #7953c3;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}

.item_selected {
  background-color: #7953c3;
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
}

.wrapper {
  position: relative;
  margin-top: 4px;

  display: inline-flex;
  justify-content: space-between;
  padding: 8px;
  background-color: #f4f4f6;
  border-radius: 8px;
  user-select: none;
}

.triple_rooms {
  padding: 8px 24px;
}
.triple_neighbours {
  padding: 8px 18px;
}
.wrapper_double{
  width: 240px;
}
.item_double {
  padding: 8px 22px;
}

.error {
  display: inline;
  color: #FF0000;
  text-align: end;
  position: absolute;
  right: 0;
  bottom: -20px;
}

.single{
  &_item{
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 9px;
    padding: 0px 0px 0px 4px;
    cursor: pointer;
    &:hover {
      .single_checkbox{
        border: 1px solid #6B39CB;
      }
    }
  }
  &_checkbox{
    border: 1px solid #7953c3;
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    img{
      transform: translate(0.8px, 0);
    }
  }
  &_active{
    .single_checkbox{
      background-color: #7953c3;
    }
    &:hover {
      .single_checkbox{
        background-color: #6B39CB;
      }
    }
  }
}


// @media (max-width: 700px) {
//   .wrapper {
//     width: 288px;
//   }

//   .item {
//     padding: 8px 18px;
//   }

//   .active {
//     padding: 8px 18px;
//   }
// }