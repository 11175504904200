.wrapper{
	display: flex;
	flex-direction: column;
	gap: 24px;
}
.wrapper_reg{
	gap: 16.4px;
	padding: 8px;
	&__item{
		padding: 8px 14px !important;
	}
  }

@media (max-width: 700px) {
	.wrapper_reg__item {
		padding: 8px 14px !important;
	}
}