@import '../../../shared/styles/colors.scss';

@keyframes skeleton {
  from {
    background: rgba(229, 229, 229, 0.3);
  }
  50% {
    background: rgba(229, 229, 229, 0.65);
  }
  to {
    background: rgba(229, 229, 229, 0.9);
  }
}

.photo {
  display: block;
  border-radius: 24px;
  object-fit: cover;
  pointer-events: none;
  cursor: pointer;

  &_preview {
    pointer-events: all;
  }
}

.photoWrapperPreview {
  width: 320px;
  height: 320px;
  overflow: hidden;
}

.emptyPhoto {
  background-color: $purple_fill;
  display: flex;
  align-items: center;
  justify-content: center;
}

.skeleton__img {
  height: 100%;
  background: rgb(229, 229, 229);
  border-radius: 24px;
  animation: skeleton 2s linear infinite alternate;
}

.wrapperPhoto {
  position: relative;
}

.right-top {
  position: absolute;
  z-index: 0;
  top: 16px;
  right: 24px;
}
.right-bottom {
  position: absolute;
  z-index: 0;
  bottom: 16px;
  right: 24px;
}
.right-middle {
  position: absolute;
  z-index: 0;
  right: 24px;
}
.left-top {
  position: absolute;
  z-index: 0;
  top: 16px;
  left: 24px;
}
.left-bottom {
  position: absolute;
  z-index: 0;
  bottom: 16px;
  left: 24px;
}
.left-middle {
  position: absolute;
  z-index: 0;
  left: 24px;
}

@media (max-width: 800px) {
  .photoWrapperPreview {
    width: 240px;
    height: 240px;
    overflow: hidden;
  }
}
