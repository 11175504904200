@import '../../styles/colors.scss';

.h1 {
  font-weight: 600;
  font-size: 48px;
  line-height: 64px;
  cursor: default;
}


.h2 {
  font-weight: 600;
  font-size: 48px;
  line-height: 64px;
  cursor: default;
}

.h3 {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  cursor: default;
}

.h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  cursor: default;
}
.noLink {
  display: block;
  text-decoration: none;
}

.purple-main {
  color: $purple_main;
}

.white-main {
  color: $white_main;
}

.black-main {
  color: $black_main;
}

.secondary-black {
  color: $secondary_black;
}

@media (max-width: 700px) {
  .h1 {
    font-size: 32px;
    line-height: 40px;
  }

  .h3 {
    font-size: 24px;
    line-height: 32px;
  }
}
