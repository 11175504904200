@import '../../../../shared/styles/colors.scss';
.wrapper{
	position: relative;
	display: inline-flex;
	flex-direction: column;
}
.input {
	padding: 16px 12px;
	min-height: 128px;
	border-radius: 10px;
	border-width: 10px;
	border-color: $purple_fill;
	border-radius: 8px;
	background-color: $purple_fill;
	font-family: Museo Sans Cyrl;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; 
	color: $black_main;
	outline:none;
	resize: none;
	&::-webkit-scrollbar{
		width: 4px;
		height: 0px;
	}
	&::-webkit-scrollbar-track{
		background: #ffffff;
	}
	&::-webkit-scrollbar-thumb{
		background: #D9D1E0;
		width: 4px;
		border-radius: 4px;
	}
}
.label {
	position: absolute;
	user-select: none;
	transition: all 0.2s ease-in-out;
}
.error{
	display: inline;
	color: #FF0000;
	text-align: end;
	// position: absolute;
	// right: 0;
	// bottom: -20px;
}