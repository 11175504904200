.wrapper {
  margin-top: 40px;
  width: 100%;
  max-width: 768px;
}

.checkboxWrapper {
  margin-top: 12px;
  display: flex;
  gap: 12px;
}

.textareaWrapper {
  margin-top: 24px;
  position: relative;
}

.checkbox {
  width: 184px;
}

.tooltip{
  top:0;
  right:-290px
}

.textarea {
  width: 100%;
}

@media (max-width: 700px) {
  .checkboxWrapper {
    flex-direction: column;
  }

  .textareaWrapper {
    width: 288px;
  }

  .checkbox {
    width: 288px;
  }
}