.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media (max-width: 800px) {
  .wrapper {
    gap: 16px;
  }
}
