@import '../../styles/colors.scss';
.stickyBlock {
  display: flex;
  padding: 8px 16px 16px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  height: 72px;
  max-width: 100%;
  width: 100%;
  z-index: 10;
  position: fixed;
  bottom: 0;
  background: $white_main;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
