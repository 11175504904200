@import '../../../../shared/styles/colors.scss';
@import '../../../../shared/styles/mixins.scss';

.room {
  padding: 24px;
  display: flex;
  column-gap: 40px;

  &__info {
    // padding-top: 8px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
  &__icon {
    position: absolute;
    left: 7px;
    top: 8px;
  }
  @include card_shadow;
  border-radius: 40px;
}
.left__column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 240px;
  row-gap: 24px;
  text-decoration: none;
}
.img {
  width: 240px;
}

.btn {
  text-decoration: none;
}

.info {
  display: block;
  &__row {
    display: flex;
    column-gap: 8px;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    margin-top: 14px;
  }
  &__icon {
    transform: translateY(1px) translateX(-2px);
  }
  &__plate {
    display: block;
  }
}

.first__row {
  display: flex;
  justify-content: space-between;
}

.first__text {
  font-size: 20px;
}

.divider {
  width: 4px;
  height: 4px;
  border-radius: 100px;
  background: $purple_main;
  opacity: 0.3;
}
.plate {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 8px;
  &__metro {
    display: flex;
    align-items: center;
    align-items: center;
    gap: 4px;
    &__name {
      display: flex;

      gap: 10px;
    }
    &__time {
      display: flex;
      align-items: center;
      gap: 6px;
    }
    &__icon {
      transform: translateY(4px);
    }
  }
  &__transport {
    transform: translateY(3px);
  }
  &__walker {
    transform: translateY(3px);
    span {
      font-size: 20px;
    }
  }
}
.descr {
  margin-top: 8px;
}
.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $purple_fill;
  border-radius: 16px;
  padding: 16px;
  gap: 12px;

  &__info {
    display: flex;
    gap: 12px;
  }
  &__dots {
    display: flex;
    column-gap: 8px;
    flex-wrap: wrap;
    align-items: center;
  }
  &__preferences {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 8px;
    align-items: center;
  }
  &__icons {
    display: flex;
    font-display: row;
    gap: 8px;
    transform: translateY(1px);
  }
  &__preference {
    display: flex;
    gap: 8px;
    align-items: center;
    transform: translateY(2px);
  }
}

.none__underline {
  text-decoration: none;
}

.avatar {
  width: 44px;
  height: 44px;
}

.squarePhoto {
  width: 240px;
  height: 240px;
  margin: 0 auto;
}

@media (max-width: 1040px) {
  .info {
    &__wrapper {
      flex-wrap: wrap;
      row-gap: 5px;
    }
  }
}

@media (max-width: 800px) {
  .room {
    column-gap: 0px;
    padding: 30px;
    flex-direction: column;
    gap: 16px;
    align-self: stretch;
  }

  .squarePhotoWrapper {
    display: flex;
    justify-content: center;
  }

  .room__info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-self: stretch;
  }

  .plate {
    margin-top: 0;
    &__metro {
      flex-wrap: wrap;
    }
  }
  .first__row {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }

  .info__wrapper {
    column-gap: 16px;
    margin-top: 0;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    row-gap: 4px;
    align-self: stretch;
    flex-wrap: wrap;
  }

  .h4_Mobile {
    font-size: 20px;
  }

  .card {
    row-gap: 8px;
  }
  .aboutPerson {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  .btn {
    height: 48px;
  }
}

.hiddenMyAd {
  opacity: 0.5;
}

@media (max-width: 572px) {
  .room {
    padding: 16px;
  }
}
