@import '../../../shared/styles/colors.scss';
@import '../../../shared/styles/mixins.scss';
.header {
  display: grid;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  &__title {
    display: flex;
    gap: 16px;
    color: #cccccc;
    align-items: flex-end;
  }
  &__subtitle {
    color: #cccccc;
    transform: translateY(-3px);
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
}

.progressBar {
  background-color: $purple_fill;
  height: 2px;
}
.progressLine {
  height: 2px;
  background-color: $purple_main;
  transition: 0.2s all;
}
.icon {
  position: absolute;
  right: 16px;
  top: 26px;
}
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 60px;
}
.nextButton {
  padding: 12px 64px;
  width: 226px;
  border-radius: 8px;
  background-color: $purple_main;
  transition: 0.2s all;
  &:hover {
    background-color: $purple_main_hover;
    transition: 0.2s all;
  }
}
.submitButton {
  padding: 12px 64px;
  width: 312px;
  border-radius: 8px;
  background-color: $purple_main;
  transition: 0.2s all;
  &:hover {
    background-color: $purple_main_hover;
    transition: 0.2s all;
  }
}
.stepsWrapper {
  width: 366px;
}
.backButton {
  padding: 8px 16px;
  border-radius: 8px;
  background: $white_main;
  border: 1px solid $purple_main;
  color: $purple_main;
  width: 72px;
  // &:hover {
  // 	border: 1px solid $purple_main_hover;
  // 	color: $purple_main_hover;
  // 	background: $purple_fill;
  //   }
}
.btns {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: start;
}
.btnToMainPage {
  min-width: 111px;
}
@media (max-width: 1040px) {
  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 30px;
  }
}
@media (max-width: 576px) {
  .slash {
    display: none;
  }
  .nextButton {
    width: 288px;
  }
  .header__title {
    justify-content: space-between;
  }
}
@media (max-width: 410px) {
  .stepsWrapper {
    width: 100%;
  }
  .submitButton {
    width: 100% !important;
    padding: 12px 60px;
  }
  .nextButton {
    width: 100%;
  }
}
