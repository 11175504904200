.UtilitiesPlate {
  background: radial-gradient(
        183.54% 164.17% at 0% 0%,
        #6b39cb 0%,
        #7953c3 50.92%,
        #cdbddb 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    #303030;
  border-radius: 8px;
  width: 209px;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform: rotate(1.5deg) translateY(4px);
  gap: 3.5px;
}

@media (max-width: 1040px) {
  .UtilitiesPlate {
    transform: none;
    border-radius: 6px;
    background: radial-gradient(
        222.08% 207.86% at 0% 0%,
        #6b39cb 0%,
        #7953c3 50.92%,
        #cdbddb 100%
      ),
      #303030;
  }
}

@media (max-width: 800px) {
  .UtilitiesPlate {
    width: 188px;
  }
}
