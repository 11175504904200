@import '../../../../shared/styles/colors.scss';

.plate {
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 8px;
  &__metro {
    display: flex;
    align-items: center;
    gap: 5px;
    &__name {
      display: flex;
      gap: 10px;
    }
    &__time {
      display: flex;
      gap: 6px;
    }
    &__icon {
      transform: translateY(4px);
    }
  }
  &__walker {
    transform: translateY(3px);
    span {
      font-size: 20px;
    }
  }
  &__transport {
    transform: translateY(3px);
  }
}
.divider {
  width: 4px;
  height: 4px;
  border-radius: 100px;
  background: $purple_main;
  opacity: 0.3;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.text {
  margin-bottom: 8px;
}
.map {
  position: relative;
  overflow: hidden;

  position: relative;
  width: 100%;
  height: 620px;
  border: 0;
  border-radius: 40px;
}

@media (max-width: 800px) {
  .map {
    iframe {
      height: 480px;
    }
  }
}
