@import "../../../styles/colors.scss";
.single {
  &_item {
    user-select: none;
    padding-top: 40px;
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 9px;
    padding: 0px 0px 0px 4px;
    cursor: pointer;
    &:hover {
      .single_checkbox {
        border: 1px solid #6b39cb;
      }
    }
  }
  &_checkbox {
    border: 1px solid #7953c3;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    img {
			align-self: center;

    }
  }
  &_active {
    .single_checkbox {
      background-color: #7953c3;
    }
    &:hover {
      .single_checkbox {
        background-color: #6b39cb;
      }
    }
  }
}
.error {
  display: inline;
  color: #ff0000;
  text-align: end;
}
.href {
  color: $purple_main;
}

.mark_wrapper{
	width:100%;
	height:110%;
	display: flex;
	justify-content: center;
	align-items: center;
}