.item {
  padding: 8px 14px;
  cursor: pointer;
  border-radius: 8px;
}
.item:hover {
  border-radius: 8px;
  cursor: pointer;
  background-color: #eeeef1;
}

.item_selected {
  padding: 8px 14px;
  background-color: #7953c3;
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
}

.wrapper {
  margin-top: 4px;
  width: 336px;
  display: inline-flex;
  justify-content: space-between;
  padding: 8px;
  background-color: #f4f4f6;
  border-radius: 8px;
  user-select: none;
}
.triple_rooms {
  padding: 8px 24px;
}

.wrapper_double {
  width: 240px;
}
.item_double {
  padding: 8px 22px;
}
