.element {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.checkbox:hover{
  padding: 12px 16px;
  border-radius: 8px;
  background-color:#EEEEF1;
  display: inline;
  user-select: none;
  cursor: pointer;
}

.input {
  display: none;
}

.checkbox{
  text-align: center;
  padding: 12px 16px;
  border-radius: 8px;
  background-color:#F4F4F6;
  display: inline;
  user-select: none;
  cursor: pointer;
}
.checkbox_selected {
  text-align: center;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: #7953c3;
  display: inline;
  user-select: none;
  cursor: pointer;
}
.checkbox__wrapper {
  display: flex;
  gap: 6px;
}