@import '../../styles/colors';

.wrapper {
  max-width: 768px;
  min-width: 288px;
  width: 100%;
  padding: 20px;
  background:rgba(255, 0, 0, 0.05);
}

.title {
  color: $button_red;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.16px;
}

.text {
  font-weight: 400;
  font-size: 14px;
  color: $black_main;
}
