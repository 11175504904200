@import '../../../../shared/styles/colors.scss';
@import '../../../../shared/styles/mixins.scss';

.neighbour {
  padding: 24px;
  display: flex;
  column-gap: 40px;
  &__info {
    padding-top: 8px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
  box-shadow: 0px 5.84789px 22.1511px rgba(133, 94, 152, 0.0500662),
    0px 5.84789px 22.1511px rgba(133, 94, 152, 0.0500662),
    0px 2.86674px 10.8589px rgba(133, 94, 152, 0.0399338),
    0px 1.13351px 4.29362px rgba(133, 94, 152, 0.0279017);
  border-radius: 40px;
}

.left__column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 240px;
  row-gap: 24px;
}

.squarePhoto {
  width: 240px;
  height: 240px;
}

.fioAndLikeRow {
  display: flex;
  justify-content: space-between;
  h4{
    cursor: pointer!important;
  }
}

.info {
  &__row {
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    align-items: center;
    justify-content: start;
    margin-top: 8px;
  }
}

.first__row {
  display: flex;
  justify-content: space-between;
}

.divider {
  width: 4px;
  height: 4px;
  border-radius: 100px;
  background: $purple_main;
  opacity: 0.3;
}

.plate {
  margin-top: 16px;
}

.descr {
  margin-top: 8px;
}

.card {
  width: 100%;
  background-color: $purple_fill;
  border-radius: 16px;
  padding: 16px;
  &__header {
    margin-top: 8px;
    cursor: pointer;
  }
}

.geo {
  &__objects {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 12px;
    margin-top: 4px;
  }
  &__object {
    display: flex;
    align-items: end;
    column-gap: 8px;
    &_adaptive {
      column-gap: 4px;
    }
  }
  &__icon {
    width: 18px;
    height: 16px;
    background-image: url('../../../../assets/icons/geo_objects/geo-object.svg');
    background-repeat: no-repeat;
  }
}

.last__row {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  gap: 20px;
  border-radius: 8px;
  padding: 0px 8px 0px 0px;
  margin-top: 12px;
  &__icons {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
}
.smoke__alchocol {
  display: flex;
  align-items: center;
}
.icon__smoke {
  display: block;
}
.icon__alchocol {
  display: block;
}
.text {
  margin-left: 8px;
}
.preferences__neighbours {
  display: flex;
  padding-right: 8px;
}
.icon {
  width: 8px;
  height: 8px;
  border-radius: 2px;
  background: $purple_main;
  transform: rotate(45deg);
}
.text_preferences {
  margin-left: 8px;
}

.none__underline {
  text-decoration: none;
}

@media (max-width: 992px) {
  .left__column {
    justify-content: center;
  }
  .geo {
    &__objects {
      row-gap: 8px;
      margin-top: 0;
    }
  }
  .last__row {
    row-gap: 8px;
  }
}

.absoluteIcon {
  position: absolute;
}

@media (max-width: 800px) {
  .neighbour {
    row-gap: 16px;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    &__info {
      padding-top: 0px;
      row-gap: 16px;
      flex-direction: column;
    }
  }

  .info {
    &__row {
      justify-content: center;
      margin-top: 0;
    }
  }
  .plate {
    margin-top: 0;
  }
  .fioEducationAndDescr {
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    align-items: center;
  }
  .card {
    &__header {
      margin-top: 12px;
    }
  }

  .price {
    font-size: 20px;
  }
  .descr {
    margin-top: 0;
  }

  .last__row {
    &__icons {
      gap: 4px;
    }
  }
}
