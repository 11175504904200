@import '../../../../shared/styles/colors.scss';

.wrapper {
  position: relative;
}

.shareForMyAd {
  // position: absolute;
  // top: 0;
  // transform: translate(100%, -100%);
  // right: -24px;
}

.firstRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plate {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 8px;
  &__metro {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    &__name {
      display: flex;
      gap: 10px;
    }
    &__time {
      display: flex;
      gap: 6px;
    }
    &__icon {
      transform: translateY(4px);
    }
  }
  &__transport {
    transform: translateY(3px);
  }
  &__walker {
    transform: translateY(3px);
    span {
      font-size: 20px;
    }
  }
}
.info {
  &__row {
    display: flex;
    column-gap: 8px;
    align-items: center;
    justify-content: start;
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    margin-top: 18px;
  }
  &__icon {
    transform: translateY(1px) translateX(-2px);
  }
}
.divider {
  width: 4px;
  height: 4px;
  border-radius: 100px;
  background: $purple_main;
  opacity: 0.3;
}

.headerMobile {
  font-size: 20px;
  line-height: 28px;
}

@media (max-width: 1200px) {
  .shareForMyAd {
    // right: 32px;
  }
}

@media (max-width: 800px) {
  .wrapper {
    border-radius: 0px 0px 16px 16px;
    background: $white_main;
    padding-bottom: 16px;
  }
  .info {
    &__wrapper {
      margin-top: 16px;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      row-gap: 4px;
    }
  }
  .plate {
    &__metro {
      row-gap: 8px;
      flex-wrap: wrap;
    }
  }
}
