.cards {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;
  margin-top: 80px;
}

@media (max-width: 800px) {
  .cards {
    margin-top: 40px;
  }
}
