@import '../../../../shared/styles/colors.scss';

.selectInput{
	&__control {
		cursor: pointer !important;
		margin-top: 4px !important;
		width: 366px !important;
		height: 56px !important;
		border-radius: 8px !important;
		padding: 16px !important;
		background-color: $purple_fill !important;
		border-width: 0px !important;
		display: flex !important;
		align-items: center !important;
	}
	&__single-value{
		margin: 0 !important;
	}
	&__value-container{
		margin: 0 !important;
		padding: 0 !important;
	}
	&__input-container{
		margin: 0 !important;
		padding: 0 !important;
	}
	&__indicator{
		padding: 0 !important;
		&-separator{
			display: none;
		}
	}
	&__placeholder{
		margin: 0 !important;
	}
	&__indicator {
		color: $purple_main !important;
	}
	&__menu {
		margin: 0 !important;
		border-radius: 8px !important;
		box-shadow: 0px 1.1335145235061646px 4.293615818023682px 0px rgba(133, 94, 152, 0.03), 0px 2.866743326187134px 10.85887622833252px 0px rgba(133, 94, 152, 0.04), 0px 5.8478875160217285px 22.15108871459961px 0px rgba(133, 94, 152, 0.05), 0px 5.8478875160217285px 22.15108871459961px 0px rgba(133, 94, 152, 0.05) !important;
		padding: 8px 0px !important;
		max-height: 280px !important;
		&-list{
			padding: 0 !important;
			max-height: 280px !important;
		}
	}
	&__option {
		padding: 12px 20px !important;
		min-height: 48px !important;
		font-family: Museo Sans Cyrl !important;
		font-size: 16px !important;
		font-style: normal !important;
		font-weight: 400 !important;
		line-height: 24px !important;
		
		&_custom{
			display: flex;
			align-items: center;
			gap: 8px;
		}
		&__icon {
			display: inline-flex;
			margin: 0;
			padding: 0;
		}
		&:hover{
			background-color: $purple_fill !important;
		}
		&--is-selected {
			background-color: $white_main !important;
			color: $purple_main !important;
		}
	}
	&__clear-indicator{
		display: none !important;
	}
	&__multi-value{
		margin: 0 !important;
		background-color: $purple_fill !important;
		padding: 4px 8px;
		border-radius: 8px;
		display: flex;
		gap: 8px;
		transition: 0.2s all;
		&__label{
			font-size: 14px !important;
			font-style: normal !important;
			font-weight: 400 !important;
			line-height: 20px !important;
			text-align: center !important;
		}
		&__remove{
			font-size: 18px !important;
			padding: 0 !important;
			// display: none;
			&:hover{
				background-color: $purple_fill !important;
			}
			svg {
				color: $secondary_black;
				transform: translateY(0.7px);
			}
		}
	}
	&__items-wrapper {
		display: flex;
		gap: 4px;
		margin-top: 4px;
		flex-wrap: wrap;
	}
	&__item{
		padding: 4px 8px;
		display: flex;
		gap: 8px;
		align-items: center;
		background-color: $purple_fill;
		border-radius: 8px;
		&__icon{
			cursor: pointer;
		}
	}
	&__error{

			display: inline;
			color: #FF0000;
			text-align: end;
		
	}
}
.select-input__error {
	display: inline;
	color: #FF0000;
	text-align: end;
  }

.select-input__wrapper {
	position: relative;
	display: inline-flex;
	flex-direction: column;
}
  
.select_invalid{
			border: 1px solid var(--ff-0000-button-red, #F00) !important;
			background: rgba(255, 0, 0, 0.05) !important;
}

@media (max-width: 410px) {
	.selectInput {
	  &__control {
		width: 100% !important;
		}
	  }
  }