@import '../../../../shared/styles/mixins.scss';
@import '../../../../shared/styles/colors.scss';

.card{
	padding: 24px 32px;
	width: 100%;
	@include default_multiple_shadow;
	display: flex;
	gap: 20px;
	align-items: center;
	border-radius: 16px;
}
.text_wrapper{
	display: flex;
	gap: 4px;
	flex-direction: column;
	width: 100%;
}
.show_me{
	border-radius: 8px;
	padding: 8px 12px;
	background-color: $purple_fill;
	display: flex;
	gap: 32px;
	width: 100%;
	align-items: center;
	justify-content: space-between;
}
.adaptive {
	display: none;
}
.icons{
	display: flex;
	img{
		width: 32px;
	}
}
@media (max-width: 610px) {
	.card {
		display: flex;
		flex-direction: column;
	}
	.icons, .text_wrapper{
		display: none;
	}
	.adaptive{
		display: flex;
		gap: 16px;
		width: 100%;
		align-items:center;
		.text_wrapper{
			display: block;
		}
		.icons {
			display: flex;
			img{
				width: 32px;
				height: 32px;
			}
		}
	}
  }