@import '../../../../shared/styles/colors.scss';
@import '../../../../shared/styles/mixins.scss';
.left__column {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}
.squarePhoto {
  width: 320px;
  height: 320px;
}

.emptyPhoto {
  background-color: $grey;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logOutBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  cursor: pointer;
  &:hover {
    .logOutText {
      color: $black_main;
    }
    .logOutIcon {
      span {
        &::before {
          color: $black_main;
        }
      }
    }
  }
}

.logOutText {
}
.logOutIcon {
}

@media (max-width: 1040px) {
  .logOutBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    &:hover {
      .logOutText {
        color: $secondary_black;
      }
      .logOutIcon {
        span {
          &::before {
            color: $secondary_black;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .squarePhoto {
    width: 240px;
    height: 240px;
  }
}
