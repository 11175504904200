@import '../../../shared/styles/colors.scss';
.overlay {
  background-color: rgba(4, 0, 19, 0.05);
  display: flex;
  justify-content: end;
  height: 100%;
  left: 0;
  overflow: scroll;
  position: fixed;
  top: 64px;
  width: 100%;
  z-index: 11;
  backdrop-filter: blur(5px);
}

.navigationСontainer {
  padding: 30px;
  min-width: 256px;
  max-width: 750px;
  background-color: $white_main;
}

.navigationContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  z-index: 11;
}

.userWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.btn {
  width: 100%;
}

.avatar {
  width: 48px;
  height: 48px;
}

.noLink {
  color: inherit;
  text-decoration: none;
}

@media (max-width: 572px) {
  .navigationСontainer {
    height: 350px;
  }
}
