.container {
  max-width: 1040px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

@media (max-width: 1040px) {
  .container {
    max-width: 90%;
  }
}

@media (max-width: 1040px) {
  .container {
    max-width: 90%;
  }
}

@media (max-width: 572px) {
  .container {
    max-width: 90%;
  }
}

@media (max-width: 1040px) {
  .container {
    max-width: 90%;
  }
}

@media (max-width: 572px) {
  .container {
    max-width: 90%;
  }
}
