@import "../../styles/colors.scss";

.toogle {
  display: flex;
  align-items: center;
  width: 40px;
  height: 20px;
  border-radius: 100px;
  background-color: $white_main;
  padding: 3px;
  cursor: pointer;
  border: 1px solid $purple_main;
  justify-content: flex-start;
  .circle {
    background-color: $purple_main;
  }
  &[data-state="true"] {
    justify-content: end;
    background-color: $purple_main;
    .circle {
      background-color: $white_main;
    }
  }
  // &_on {
  //   background-color: $purple_main;
  //   justify-content: end;
  //   .circle {
  //     background-color: $white_main;
  //   }
  // }
  // &_off {
  //   border: 1px solid $purple_main;
  //   .circle {
  //     background-color: $purple_main;
  //   }
  // }
  &_disabled {
    background-color: $purple_fill;
    border: 1px solid #aaaaaa;
    cursor: auto;
    .circle {
      background-color: #aaaaaa;
    }
  }
}

.circle {
  transition: none;
  width: 14px;
  height: 14px;
  border-radius: 100px;
}
