@import '../../shared/styles/colors.scss';
.logo{
	width: 192px;
	margin-bottom: 80px;
}
.wrapper {
	padding-top: 56px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.href{
	color: $purple_main;
	text-decoration: underline;
}
.btn{
	width: 406px;
}
@media (max-width: 576px) {
	.logo {
		width: 134px;
		margin-bottom: 40px;
	}
	.btn{
		width: 100%;
	}
	.btn_wrapper{
		font-size: 14px;
	}
	.text {
		width: 200px;
	}
  }