@import "../../../styles/colors.scss";
.component {
  display: flex;
  flex-direction: column;
}
.single {
  &_item {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 9px;
    padding: 0px 0px 0px 4px;
    cursor: pointer;
    &:hover {
      .single_checkbox {
        border: 1px solid #6b39cb;
      }
    }
  }
  &_checkbox {
    border: 1px solid #7953c3;
    display: flex;
		align-items: center;
		justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    img {
      transform: translate(0.5px, 1px);
			align-self: center;
    }
    &_invalid {
      border: 1px solid $button_red;
    }
  }
  &_active {
    .single_checkbox {
      background-color: #7953c3;
    }
    &:hover {
      .single_checkbox {
        background-color: #6b39cb;
      }
    }
  }
}
.wrapper {
  display: flex;
  gap: 16px;
  user-select: none;
}
.error {
  display: inline;
  color: $button_red;
  text-align: end;
}
@media (max-width: 410px) {
  .wrapper {
    flex-direction: column;
    gap: 0px;
  }
}
