.slider {
	background: #D9D1E0;
	height: 2px;
}
.range-slider .range-slider__thumb[data-disabled]{
	display: none;
}
.slider .range-slider__range {
	background: #7953C3;
	/* left: 0px !important; */
	border-radius: 2px;
  }
.slider .range-slider__thumb {
	width: 16px;
	height: 16px;
	background: rgba(0,0,0,0);
	background-image: url(../../../../assets/icons/functional_icons/slider.svg);
  }
.input-wrapper {
	user-select: none;
	margin-top: 4px;
	position: relative;
	display: inline-flex;
	padding: 16px;
	align-items: center;
	justify-content: space-between;
	background-color:#F4F4F6;
	border-radius: 8px;
	width: 336px;
}
.text {
	opacity: 0.5;
}
.input {
	display: inline-flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
}
.input .input__item {
	width: 70px;
	min-width: 30px;
	max-width: 80px;
	border: none;
	background-color:#F4F4F6;
	transform: translateY(1px);
	cursor: pointer;
	outline: none;
	transition: 0.2s;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}
.input__text{
	font-size: 14px;
	color: #5D5B66;
	opacity: 50%;
}
.divider {
	position: absolute;
	left: 50%;
	transform: translateX(-16px);
	top: 27px;
	display: inline;
	width: 16px;
	height: 2px;
	background: #D9D9D9;
}
.slider-wrapper {
	user-select: none;
	padding: 0px 16px 0px 16px;
	transform: translateY(-1px);
	width: 100%;
}
.small {
	width: 224px;
}
.error{
	display: inline;
	color: red;
	text-align: end;
}