@font-face {
  font-family: 'icomoon';
  src: url('fonts_2/icomoon.eot?zbqar8');
  src: url('fonts_2/icomoon.eot?zbqar8#iefix') format('embedded-opentype'),
    url('fonts_2/icomoon.ttf?zbqar8') format('truetype'),
    url('fonts_2/icomoon.woff?zbqar8') format('woff'),
    url('fonts_2/icomoon.svg?zbqar8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
  content: '\e1000';
  color: #7953c3;
}
.icon-burger:before {
  content: '\e1001';
  color: #7953c3;
  font-size: 28px;
}
.icon-calendar:before {
  content: '\e1002';
  color: #5d5b66;
}
.icon-cross_rounded:before {
  content: '\e1003';
  color: white;
  font-size: 30px;
}
.icon-cross_square:before {
  content: '\e1004';
  color: #7953c3;
  font-size: 12px;
}
.icon-dots:before {
  content: '\e1005';
  color: #7953c3;
  font-size: 30px;
}
.icon-search:before {
  content: '\e1006';
  color: #5d5b66;
}
.icon-trash:before {
  content: '\e1007';
  color: #5d5b66;
}

@media (max-width: 800px) {
  .icon-burger:before {
    content: '\e1001';
    font-size: 23px;
  }
  .icon-cross_rounded:before {
    content: '\e1003';
    color: white;
    font-size: 28px;
  }
}
