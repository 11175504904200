@font-face {
	font-family: 'icomoon';
	src: url('fonts_3/icomoon.eot?zbqar8');
	src: url('fonts_3/icomoon.eot?zbqar8#iefix') format('embedded-opentype'),
	  url('fonts_3/icomoon.ttf?zbqar8') format('truetype'),
	  url('fonts_3/icomoon.woff?zbqar8') format('woff'),
	  url('fonts_3/icomoon.svg?zbqar8#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
  }
  
  [class^='icon-'],
  [class*=' icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
  
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }
  .icon-email-letter:before {
	content: '\e9007';
	color: #fff;
  }