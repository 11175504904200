@import '../../../../shared/styles/colors.scss';

.select {
  &__control {
    cursor: pointer !important;
    margin-top: 4px !important;
    height: 56px !important;
    border-radius: 8px !important;
    padding: 16px !important;
    background-color: $purple_fill !important;
    border-width: 0px !important;
    display: flex !important;
    align-items: center !important;
  }
  &__single-value {
    margin: 0 !important;
  }
  &__value-container {
    margin: 0 !important;
    padding: 0 !important;
  }
  &__input-container {
    margin: 0 !important;
    padding: 0 !important;
  }
  &__indicator {
    padding: 0 !important;
    &-separator {
      display: none;
    }
  }
  &__placeholder {
    margin: 0 !important;
  }
  &__indicator {
    color: $purple_main !important;
  }
  &__menu {
    margin: 0 !important;
    border-radius: 8px !important;
    box-shadow: 0px 1.1335145235061646px 4.293615818023682px 0px
        rgba(133, 94, 152, 0.03),
      0px 2.866743326187134px 10.85887622833252px 0px rgba(133, 94, 152, 0.04),
      0px 5.8478875160217285px 22.15108871459961px 0px rgba(133, 94, 152, 0.05),
      0px 5.8478875160217285px 22.15108871459961px 0px rgba(133, 94, 152, 0.05) !important;
    padding: 8px 0px !important;
    &-list {
      padding: 0 !important;
    }
  }
  &__option {
    padding: 12px 20px !important;
    min-height: 48px !important;
    font-family: Museo Sans Cyrl !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    &:hover {
      background-color: $purple_fill !important;
    }
    &--is-selected {
      background-color: $white_main !important;
      color: $purple_main !important;
    }
  }
}
