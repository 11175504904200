@import '../../../../shared/styles/colors.scss';

.plateWrapper {
  display: flex;
  max-width: 1280px;
  min-height: 72px;
  padding: 16px 24px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background-color: $purple_fill;
}

.rightPannel {
  display: flex;
  align-items: center;
  column-gap: 16px;
  &_rejected {
    column-gap: 24px;
  }
}

.btn {
  min-width: 168px;
  height: 40px;
}

.btnText {
  &:hover {
    background: $white-main;
  }
}

.deleteBtn {
  display: flex;
  column-gap: 12px;
  width: 245px;
  &:hover {
    .deleteIcon {
      span {
        &::before {
          color: $black_main;
        }
      }
    }
  }
}

.deleteText {
}

.textWrapper {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
}

.btnsGroup {
  display: flex;
  gap: 16px;
  align-items: center;
}

.onModeration {
  background-color: $purple_fill;
}

.rejected {
  display: flex;
  padding: 8px 24px 8px 12px;
  align-items: center;
  border-radius: 8px;
  gap: 12px;
  background-color: $button_red;
}

.hidden {
  background-color: $purple_fill;
}

.visible {
  display: none;
}

@media (max-width: 1280px) {
  .plateWrapper {
    max-width: 100%;
  }
  .btnText {
    background: $white-main;
  }
}

@media (max-width: 1040px) {
  .deleteBtn {
    display: flex;
    column-gap: 12px;
    width: 245px;
    &:hover {
      .deleteIcon {
        span {
          &::before {
            color: $button_red;
          }
        }
      }
    }
  }
  .deleteIcon {
    span {
      &::before {
        color: $button_red;
      }
    }
  }
  .deleteText {
    color: $button_red;
  }
}

@media (max-width: 950px) {
  .plateWrapper {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 16px;
    row-gap: 40px;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
  }
  .btnsGroup {
    flex-direction: column;
    width: 100%;
  }
  .btn {
    width: 100%;
  }
  .rightPannel_rejected {
    row-gap: 16px;
    flex-direction: column;
  }
}
