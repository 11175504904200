@import '../../../../shared/styles/colors.scss';
.wrapper {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}

.input {
  width: 100%;
  padding: 16px 40px 16px 16px;
  min-height: 56px;
  border-radius: 8px;
  background-color: $purple_fill;
  font-family: Museo Sans Cyrl;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: $black_main;
  outline: none;
  overflow-wrap: normal;
  &::-webkit-input-placeholder {
    color: #a1a6b1;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &::placeholder {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #a1a6b1;
  }
}

.label {
  fontsize: 12px;
  fontweight: 400;
  lineheight: 20px;
  top: -18px;
  position: absolute;
  user-select: none;
  color: #5d5b66;
}

.error {
  display: block;
  color: #ff0000;
  text-align: end;
  right: 0;
  margin-top: 5px;
}

.search {
  cursor: pointer;
  top: 21px;
  position: absolute;
  right: 16px;
  width: 16px;
  height: 16px;
}

.hidden {
  display: none;
}
