@import '../../../../shared/styles/colors.scss';
.single{
	
	&_item{
		user-select: none;
		padding-top: 40px;
	  display: flex;
	  gap: 8px;
	  align-items: center;
	  margin-top: 9px;
	  padding: 0px 0px 0px 4px;
	  cursor: pointer;
	  &:hover {
		.single_checkbox{
		  border: 1px solid #6B39CB;
		}
	  }
	}
	&_checkbox{
		border: 1px solid #7953c3;
		display: inline-block;
		width: 16px;
		min-width: 16px;
		height: 16px;
		border-radius: 4px;
		img{
			transform: translate(0.8px, 0);
		}
	  }
	  &_active{
		.single_checkbox{
		  background-color: #7953c3;
		}
		&:hover {
		  .single_checkbox{
			background-color: #6B39CB;
		  }
		}
	  }
	}
	.error{
		display: inline;
		color: #FF0000;
		text-align: end;
	}
	.href {
		color: $purple_main;
	}