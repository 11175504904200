@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?grl2u3');
  src: url('fonts/icomoon.eot?grl2u3#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?grl2u3') format('truetype'),
    url('fonts/icomoon.woff?grl2u3') format('woff'),
    url('fonts/icomoon.svg?grl2u3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-1-branch-metro:before {
  content: '\e90a';
  color: #6c84db;
  width: 4px !important;
  height: 4px;
}
.icon-2-branches-metro:before {
  content: '\e90b';
  color: #6c84db;
}
.icon-3-branches-metro:before {
  content: '\e90c';
  color: #6c84db;
}
.icon-4-branches-metro:before {
  content: '\e90d';
  color: #6c84db;
}
.icon-slider .path1:before {
  content: '\e90f';
  color: rgb(121, 83, 195);
}
.icon-slider .path2:before {
  content: '\e910';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-no-alcohol:before {
  content: '\e911';
  color: #c35367;
  font-size: 23px;
}
.icon-ok:before {
  content: '\e912';
  color: #f80;
  font-size: 21px;
}
.icon-pixel:before {
  content: '\e913';
  color: #7953c3;
  font-size: 10px;
}
.icon-no-smoking:before {
  content: '\e914';
  color: #c35367;
  font-size: 22px;
}
.icon-car:before {
  content: '\e915';
  color: #5d5b66;
}
.icon-walker:before {
  content: '\e916';
  color: #5d5b66;
}
.icon-vk:before {
  content: '\e917';
  color: #2787f5;
}
.icon-telegram:before {
  content: '\e900';
  color: #fff;
}
// .icon-arrow:before {
//   content: '\e901';
//   color: #7953c3;
// }
.icon-share:before {
  content: '\e902';
}
.icon-exit:before {
  content: '\e903';
  color: #5d5b66;
}
.icon-copy:before {
  content: '\e904';
  color: #5d5b66;
}
.icon-favorites-tonal:before {
  content: '\e905';
}
.icon-phone:before {
  content: '\e906';
  color: #fff;
}
.icon-complain:before {
  content: '\e907';
}
.icon-edit:before {
  content: '\e908';
}
.icon-favorites-filled:before {
  content: '\e909';
}
