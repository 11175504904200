.neighbour {
  padding: 24px;
  display: flex;
  column-gap: 40px;
  &__info {
    padding-top: 8px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
  &__icon {
    position: absolute;
    right: 16px;
    top: 8px;
  }
  box-shadow: 0px 5.84789px 22.1511px rgba(133, 94, 152, 0.0500662),
    0px 5.84789px 22.1511px rgba(133, 94, 152, 0.0500662),
    0px 2.86674px 10.8589px rgba(133, 94, 152, 0.0399338),
    0px 1.13351px 4.29362px rgba(133, 94, 152, 0.0279017);
  border-radius: 40px;
}

.cards {
  display: flex;
  flex-direction: column;
  max-width: 1040px;
  row-gap: 24px;
  margin-top: 80px;
}

.empty__wrapper {
  text-align: center;
  margin-top: 80px;
}

.empty__text {
  margin-top: 8px;
}

@media (max-width: 800px) {
  .cards {
    margin-top: 40px;
  }
}

@media (max-width: 572px) {
  .empty__text {
    margin-top: 4px;
  }
}
