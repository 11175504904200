@import '../../../../shared/styles/colors.scss';
.single{
	&_item{
	user-select: none;
	padding-top: 40px;
	display: flex;
	gap: 8px;
	align-items: center;
	margin-top: 40px;
	cursor: pointer;
		&:hover {
			.single_checkbox{
				border: 1px solid $purple_main_hover;
			}
		}	
	}
	&_checkbox{
		border: 1px solid $purple_main;
		box-sizing: border-box;
		display: inline-block;
		width: 16px;
		height: 16px;
		border-radius: 4px;
		img{
			transform: translate(1px, -1px);
		}
	}
	&_active{
		.single_checkbox{
			background-color: $purple_main;
		}
		&:hover {
			.single_checkbox{
			background-color: $purple_main_hover;
			}
		}
	}
}
.error{
	display: inline;
	color: #FF0000;
	text-align: end;
}
@media (max-width: 576px) {
	.single {
		&_checkbox{
			width: 16px;
			height: 16px;
			img {
				transform: translate(1px, 0px);
			}
		}
		&_item{
			margin-top: 20px;
		}
	}
  }
