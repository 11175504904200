@import '../../../../shared/styles/colors.scss';
@import '../../../../shared/styles/mixins.scss';

.settings__card {
  display: flex;
  padding: 16px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 20px;
  @include card_shadow;
}
.contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}
.plate__showingMe {
  display: flex;
  width: 100%;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: $purple_fill;
}

@media (max-width: 1040px) {
  .settings__card {
    background: $white_main;
    gap: 16px;
  }
  .plate__showingMe {
    justify-content: flex-start;
    gap: 20px;
  }
}

@media (max-width: 800px) {
  .settings__card {
    border-radius: 20px;
  }
}
