@import '../../../../shared/styles/colors.scss';

.plate {
  padding: 8px 12px 8px 12px;
  width: max-content;
  border-radius: 8px;
  display: flex;
  gap: 12px;
}

.onModeration {
  background-color: $purple_fill;
}

.rejected {
  background-color: $button_red;
}

.hidden {
  background-color: $purple_fill;
}

.visible {
  display: none;
}

@media (max-width: 800px) {
  .plate {
    width: auto;
  }
}
