@import '../../../../shared/styles/colors.scss';
@import '../../../../shared/styles/mixins.scss';

.react-datepicker {
	border: 0 !important;
	width: 100% !important;
	@include default_multiple_shadow;
	font-size: 14px;
	&__input-container{
		input {
			padding: 12px 8px 12px 12px;
			width: 200px !important;
			height: 56px;
			background-color:$purple_fill ;
			border-radius: 8px;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
			outline:none;
			&::-webkit-input-placeholder{
				color: #626771;
			}
		}
	}
	&__month-container{
		width: 100% !important;
	}
	&-popper{
		width: 280px !important;
		padding: 0 !important;
	}
	&__triangle{
		display: none;
	}
	&__navigation-icon::before{
		border-color: $purple_main !important;
	}	
	&__navigation--previous {
		left: 40px !important;
	}
	&__navigation--next {
		right: 40px !important;
	}
	&__header {
		background-color: $white_main !important;
		padding-bottom: 0 !important;
		border: 0 !important;
		text-align: center;
		
	}
	&__year--container{
		border-radius: 8px !important;
	}
	&__year-wrapper {
		display: flex !important;
		flex-direction: row;
		gap: 10px;
		max-width: none !important;
		justify-content: space-around;
	}
	&__year {
		margin: 0 !important;
		padding: 16px;
	}
	&__year-text{
		margin: 0 !important;
		padding: 4px;
		font-size: 16px !important;
		width: 50px !important;
		&--selected{
			border-radius: 8px !important;
			background-color: white !important;
			color:  $purple_main !important;
			font-weight: 900 !important;
		}
		&--today{
			font-weight: 100 !important;
		}
		&--keyboard-selected{
			 background-color: white !important;
		}
	}
	
}
.yearInput_invalid {
	.react-datepicker__input-container {
		input {
			border: 1px solid var(--ff-0000-button-red, #F00) !important;
			background: rgba(255, 0, 0, 0.05) !important;
		}
	}
}

.year-picker {
	// transform: translate3d(110%, 702px, 0px) !important;
	.react-datepicker__current-month, .react-datepicker__navigation {
		display: none;
	}
	.react-datepicker__day {
		margin: 0 !important;
		padding: 4px 14px;
		text-align: center !important;
	}
	.react-datepicker__day-names, .react-datepicker__week{
		display: flex !important;
		justify-content: space-between;
	}
	.react-datepicker__day-name{
		padding: 6.5px 12px 4px 6.5px !important;
		margin: 0 !important;
		width: 48px !important;
		height: 36px;
	}
	.react-datepicker__month-container{
		padding: 8px;
	}
	.react-datepicker__day{
		margin: auto !important;
		width: 32px !important;
		padding: 4px 0!important;
		height: 36px;
	}
	.react-datepicker__month {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.react-datepicker__day--outside-month{
		color: #9E9E9E;
	}
	.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected{
			border-radius: 8px !important;
			background-color: white !important;
			color:  $purple_main !important;
			font-weight: 900 !important;
	}
	.react-datepicker__month-select, .react-datepicker__year-select{
		outline:none;
		padding: 8px 12px !important;
		background-color: #F4F4F6 !important;
		border-radius: 8px !important;
	}
	.react-datepicker__day--today{
		font-weight: normal !important;
		color: black !important;

	}
	.react-datepicker__month-select:after{
		width: 16px;
		height: 16px;
		background-color:#F00;
		background-image: -moz-element();
	}
}
.yearInput_wrapper {
	width: 200px;
	display: flex;
	flex-direction: column;
}
.error {
	display: inline;
	color: #FF0000;
	text-align: end;
}