@import '../../../../shared/styles/mixins.scss';
@import '../../../../shared/styles/colors.scss';
Text {
	font-size: 9px;
	font-style: normal;
	font-weight: 400;
	line-height: 11px;
}
.comment{
	padding-left: 16px;
	margin-bottom: 16px;
}
.card {
	display: flex;
	gap: 28px;
	padding: 20px;
	border-radius: 32px;
	@include card_shadow;
	margin-bottom: 24px;
	&__left{
		display: flex;
		flex-direction: column;
		gap: 12px;
		width: 140px;
	}
	&__photo{
		height: 140px;
		border: 1px dashed var(--d-9-d-1-e-0-stroke, #CDBDDB);
		border-radius: 16px;
	}
	&__button{
		padding: 6px 12px;
		height: 22px;
		width: 140px;
		font-size: 8px;
		font-weight: 600;
		line-height: 10px;
		color: $white_main;
		border-radius: 4px;
		background-color: $purple_main;
		transition: 0.2s all;
		cursor: pointer;
		&:hover {
			background: $purple_main_hover;
		  }
	}
}
.info {
	&__row {
	  display: flex;
	  column-gap: 4px;
	  align-items: center;
	  justify-content: start;
	  margin-top: 7px;
	}
  }
  .divider {
	width: 4px;
	height: 4px;
	border-radius: 100px;
	background: $purple_main;
	opacity: 0.3;
  }
  .plate {
	margin-top: 10px;
	height: 17px;
	min-width: 80px;
	max-width: 400px;
	padding: 3px 6px;
  }
  .descr {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	align-self: stretch;
	margin-top: 10px;
	&_unwrap {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		align-self: stretch;
		margin-top: 10px;
	}
  }
  
  .arrow_down {
	transform: rotate(90deg) translateX(-3px);
	span {
	  &::before {
		font-size: 8px;
	  }
	}
  }
  .arrow_up {
	transform: rotate(270deg) translateX(4px) translateY(-3px);
	span {
	  &::before {
		font-size: 8px;
	  }
	}
  }
  .arrow__wrapper {
	height: 11px;
	display: flex;
	align-items: start;
	gap: 4px;
	cursor: pointer;
	&:hover {
	  .arrow__text {
		color: $purple_main_hover;
	  }
	  .arrow {
		span {
		  &::before {
			color: $purple_main_hover;
		  }
		}
	  }
	}
  }
  .name{
	min-height: 22px;
	width: 150px;
	background-color:  $purple_fill;
	border-radius: 6px;
  }
  .row_descr {
	height: 11px;
	width: 32px;
	background-color:  $purple_fill;
	border-radius: 6px;
	&__edu{
		height: 11px;
		width: 120px;
		background-color:  $purple_fill;
		border-radius: 6px;
	}
  }
  .about_me {
	margin-top: 10px;
	width: 332px;
	height: 11px;
	border-radius: 6px;
	background-color: $purple_fill;
  }
  .preferences{
	margin-top: 20px;
	padding: 12px 20px 12px 20px;
	width: 406px;
	background: $purple_fill;
	border-radius: 8px;
	&__price {
		border-radius: 6px;
		min-height: 20px;
		width: 198px;
		background-color:  $filter_hover;
		margin-top: 4px;
	}
	&__underground {
		border-radius: 4px;
		min-height: 14px;
		width: 308px;
		background-color:  $filter_hover;
		margin-top: 2px;
	}
	&__devider{
		height: 1px;
		background-color: $button_grey;
		margin-top: 8px;
		margin-bottom: 8px;
	}
	&__wrapper{
		padding: 3px 4px 3px 0px;
		border-radius: 8px;
		display: flex;
		gap: 4px;
		align-items: center;
		&_active {
			background: linear-gradient(270deg, #F4F4F6 0%, rgba(244, 244, 246, 0.00) 100%);
			height: 22px;
		}
	}
	&__habits{
		min-height: 16px;
		border-radius: 4px;
		width: 158px;
		background-color:  $filter_hover;
	}
	&__text{
		min-height: 16px;
		border-radius: 4px;
		width: 202px;
		background-color:  $filter_hover;
	}
	&_active {
		padding: 12px 20px 12px 12px;
		gap: 8px;
		margin-top: 20px;
		width: 406px;
		border-radius: 8px;
		@include card_3D
	}
  }
.metroWrapper {
	display: flex;
	align-items: center;
	gap: 3px;
}
.checkHabits {
	display: flex;
	align-items: center;
}
.icon_habits{
	transform: translateX(-10px) scale(0.5);
  }
.image {
	width: 100%;
	height: 100%;
	object-fit: cover;
  }
.imageWrap {
	border: 1px solid #EBEBEB;
	border-radius: 16px;
	width: 140px;
	height: 140px;
	overflow: hidden;
	position: relative;
	transition: 0.2s all;
  }
@media (max-width: 1040px) {
	.component {
		display: none;
	}
}