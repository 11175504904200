.wrapper {
  margin-top: 40px;
}

.multipleWrapper {
  max-width: 336px;
  width: 100%;
}

.flat_params__wrapper{
  width:100%;
  max-width: 768px;
}

.multiple {
  padding: 8px 14px;
  height: 56px;
  width:100%;
  &__item {
    padding: 8px 14px;
  }
}

.multipleBath {
  padding: 8px 18px;
}

.selects {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 12px;
  width: 100%;
  div:first-child {
    width: 100%;
    max-width: 338px;
  }
}

.floor {
  width: 160px;
}

.square {
  width: 100%;
}

.address {
  width: 100%;
}

.addressWrapper {
  margin-top: 28px;
  position: relative;
}

.bathWrapper {
  margin-top: 40px;
  display: flex;
  gap:15px;
  justify-content: space-between;
  align-items: flex-end;
  div{
    width: 100%;
  }
}
.tooltip{
  top:70px;
  right:-290px;
}

.bath {
  width: 100%;
  &__item {
    padding: 8px 18px;
  }
}

.wrapperInput {
  width: 224px;
}

.bathIsCombined {
  width: 100%;
  &__item {
    padding: 8px 18px;
  }
}

.plate {
  width: 254px;
  &__item {
    padding: 8px 18px;
  }
}

.map {
  margin-top: 16px;
  border-radius: 8px;
}

@media (max-width: 700px) {
  .multipleWrapper {
    width: 288px;
  }
  
  .selects {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
  }

  .wrapper {
    width: 288px;
  }

  .multiple {
    padding: 8px 10px;
  }

  .squareWrapper {
    width: 288px;
  }

  .square {
    width: 100%;
  }

  .addressWrapper {
    margin-top: 48px;
  }

  .bathWrapper {
    margin-top: 24px;
    flex-direction: column;
    gap: 24px;
  }

  .bath {
    width: 288px;
  }

  .bathIsCombined {
    width: 288px;
  }

  .plate {
    width: 288px;
  }
}