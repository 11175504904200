@import url('../../../../shared/styles/colors.scss');
.wrapper {
	display: flex;
	gap: 16px;
	position: relative;
}
.error{
	display: inline;
	color: #FF0000;
	text-align: end;
}
.input {
	position: relative;
	padding-left: 4px;
}
// .button {
// 	display: flex;
// 	gap: 8px;
// }
// .item{
// 	position: absolute;
// 	z-index: -1;
// 	opacity: 0;
// }
// .item+label {
// 	display: inline-flex;
// 	align-items: center;
// 	user-select: none;
//   }
//   .item+label::before {
// 	content: '';
// 	display: inline-block;
// 	margin-right: 8px;
// 	background-repeat: no-repeat;
// 	background-position: center center;
// 	width: 16px;
// 	height: 16px;
// 	background-image: url(../../../../assets/icons/functional_icons/radio_default.svg);
// 	transition: all 0.2s ease-in-out;
//   }
//   .item:checked+label::before {
// 	background-image: url(../../../../assets/icons/functional_icons/radio_active.svg);
// 	transition: all 0.2s ease-in-out;
//   }
//   .item:not(:disabled):not(:checked)+label:hover::before {
// 	background-image: url(../../../../assets/icons/functional_icons/radio_hover.svg);
// 	transition: all 0.2s ease-in-out;
//   }
// .item:disabled+label::before {
// 	background-image: url(../../../../assets/icons/functional_icons/radio_error.svg);
// 	transition: all 0.2s ease-in-out;
// 	}
.radio {
	width: 16px;
	height: 16px;
	background-image: url(../../../../assets/icons/functional_icons/radio_default.svg);
	:hover{
	background-image: url(../../../../assets/icons/functional_icons/radio_hover.svg);
	transition: all 0.2s ease-in-out;
	}
	&_active{
		transition: all 0.2s ease-in-out;
		background-image: url(../../../../assets/icons/functional_icons/radio_active.svg);
	}
}
.label{
	display: flex;
	gap: 8px;
	cursor: pointer;
}
// .radiobutton{
// 	&_label {
// 		display: flex;
// 		align-items: center;
// 		gap: 5px;
// 		cursor: pointer;
// 	}
// 	&_input {
// 		visibility: hidden;
// 		// &:checked + .radiobutton_custom {
// 		// 	border: 2px solid #261ba4;
//  		// 	background-color: #3f27b3;
// 		// }
// 	}
// 	&_custom{
// 		height: 17px;
// 		width: 17px;
// 		border-radius: 50%;
// 		cursor: pointer;
// 		background-color: #d2c5ef;
// 		display: inline-block;
// 	}
// }
// // .radiobutton_input:checked + .radiobutton_custom {
// // 	border: 2px solid #261ba4;
// // 	background-color: #3f27b3;
// //   }
// .radiobutton_input:checked + span {
//   border: 2px solid #261BA4;
//   background-color: #3F27B3;
// }