.wrapper{
	padding: 2px;
	width: 18px;
	height: 18px;
}
.one_dot{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 10px;
}
.two_dots{
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.three_dots{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items:center;
	gap: 2px;
}
.four_dots{
	display: grid !important;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 2px;
	justify-items: center;
}
.mcd {
	padding: 3px 3px;
	border-radius: 4px;
	width: 16px;
	height: 14px;
	text-align: start;
	&__text {
		font-size: 8px;
		font-weight: 600;
		color: white;
		width: 10px;
		height: 9.2px;
		line-height: 9px;
	}
}