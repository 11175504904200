@font-face {
  font-family: 'Museo Sans Cyrl';
  src: url('MuseoSansCyrl-700.woff2') format('woff2'),
    url('MuseoSansCyrl-700.woff') format('woff'),
    url('MuseoSansCyrl-700.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Museo Sans Cyrl';
  src: url('MuseoSansCyrl-500.woff2') format('woff2'),
    url('MuseoSansCyrl-500.woff') format('woff'),
    url('MuseoSansCyrl-500.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
