@import '../../../../shared/styles/colors.scss';
@import '../../../../shared/styles/mixins.scss';

.room {
  padding: 24px;
  display: flex;
  column-gap: 40px;

  &__info {
    padding-top: 8px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
  &__icon {
    position: absolute;
    left: 7px;
    top: 8px;
  }
  @include card_shadow;
  border-radius: 40px;
}
.left__column {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  width: 240px;
  row-gap: 24px;
  text-decoration: none;
}
.img {
  width: 240px;
}

.btn {
  text-decoration: none;
}

.info {
  display: block;
  &__row {
    display: flex;
    column-gap: 8px;
    align-items: center;
    justify-content: start;
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    margin-top: 14px;
  }
  &__icon {
    transform: translateY(1px) translateX(-2px);
  }
  &__plate {
    display: block;
  }
}

.divider {
  width: 4px;
  height: 4px;
  border-radius: 100px;
  background: $purple_main;
  opacity: 0.3;
}
.plate {
  margin-top: 18px;
  &__metro {
    display: flex;
    align-items: center;
    gap: 4px;
    &__name {
      display: flex;
      gap: 10px;
    }
    &__time {
      display: flex;
      gap: 6px;
    }
    &__icon {
      transform: translateY(4px);
    }
  }
  &__walker {
    transform: translateY(3px);
  }
}
.descr {
  margin-top: 8px;
}
.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $purple_fill;
  border-radius: 16px;
  padding: 16px;
  gap: 12px;
  &__info {
    display: flex;
    gap: 12px;
  }
  &__dots {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  &__preferences {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  &__icons {
    display: flex;
    font-display: row;
    gap: 8px;
    transform: translateY(1px);
  }
  &__preference {
    display: flex;
    gap: 11px;
    align-items: center;
    transform: translateY(2px);
  }
}

.none__underline {
  text-decoration: none;
}

.avatar {
  width: 48px;
  height: 48px;
}

.squarePhoto {
  width: 240px;
  height: 240px;
}

.hiddenMyAd {
  opacity: 0.5;
}
