@import '../../../shared/styles/colors.scss';
@import '../../../shared/styles/mixins.scss';

.footer {
  padding: 120px 0px 0px 0px;
  border-top: 1px solid #ebebeb;
  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 80px;
  }
}

.technical__support {
  margin-top: 8px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: $black_main;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.links {
  display: flex;
  align-items: center;
  column-gap: 32px;
  margin-top: 40px;
}
.social {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.divider {
  width: 100%;
  opacity: 0.2;
  border: 1px solid $purple_main;
}
.text {
  display: flex;
  justify-content: space-between;
  align-items: end;
  &_1 {
    max-width: 655px;
  }
  cursor: default;
}

.line {
  width: 100%;
  height: 12px;
  background: $purple_main;
  margin-top: 64px;
}

.noLink {
  text-decoration: none;
  color: inherit;
  &:hover {
    color: $purple_main_hover;
  }
}

.link {
  text-decoration: none;
  &:hover {
    color: $purple_main_hover;
  }
}

@media (max-width: 1040px) {
  .footer {
    padding: 80px 0px 0px 0px;
  }
  .technical__support {
    &:hover {
      color: inherit;
    }
  }
}

@media (max-width: 600px) {
  .footer {
    padding: 64px 0px 0px 0px;
    &__content {
      row-gap: 40px;
    }
  }
  .emailAndLinks {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  .links {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-top: 0;
  }
  .text {
    flex-direction: column;
    gap: 40px;
    align-items: flex-start;
  }
  .row {
    flex-direction: column;
    row-gap: 24px;
  }
  .social {
    align-items: flex-start;
    gap: 8px;
  }
  .line {
    margin-top: 40px;
  }
}
