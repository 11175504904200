@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.card {
  padding: 22px 41px;
  display: flex;
  flex-direction: row;
  gap: 19px;
  background-color: $white-main;
  box-shadow: 0px 5.84789px 22.1511px rgba(133, 94, 152, 0.0500662),
    0px 5.84789px 22.1511px rgba(133, 94, 152, 0.0500662),
    0px 2.86674px 10.8589px rgba(133, 94, 152, 0.0399338),
    0px 1.13351px 4.29362px rgba(133, 94, 152, 0.0279017);
  border-radius: 8px;
  width: 336px;
  height: 68px;
}

.icon {
  display: block;
}
.skeleton__icon {
  width: 28px;
  height: 28px;
  background: $purple_fill;
}

@media (max-width: 800px) {
  .card {
    width: 140px;
    height: 80px;
    padding: 8px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    /* Default multiple shadows */
    box-shadow: 0px 1.13351px 4.29362px 0px rgba(133, 94, 152, 0.03),
      0px 2.86674px 10.85888px 0px rgba(133, 94, 152, 0.04),
      0px 5.84789px 22.15109px 0px rgba(133, 94, 152, 0.05),
      0px 5.84789px 22.15109px 0px rgba(133, 94, 152, 0.05);
  }
}
