
@import '../../../shared/styles/colors.scss';

.tooltip{
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  width: 256px;
  border-radius: 8px;
  background-color: $purple_main;
  color: $white_main;
  &__corner{
    position: absolute;
    left: -7px;
    top: 10px;
  }
  &__cross{
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 8px;
  }
  &__title{
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    max-width: 194px;
  }
  &__text{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}

