@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';

.overlay {
  background-color: rgba(4, 0, 19, 0.05);
  display: block;
  height: 100%;
  left: 0;
  overflow: scroll;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  backdrop-filter: blur(5px);
}
