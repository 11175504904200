@import '../../../../shared/styles/colors.scss';

.left__column {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
}

.squarePhoto {
  width: 320px;
  height: 320px;
}

.emptyPhoto {
  background-color: $grey;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icons {
  display: flex;
  column-gap: 16px;
  align-items: baseline;
  justify-content: center;
}

@media (max-width: 800px) {
  .squarePhoto {
    width: 240px;
    height: 240px;
  }
}
