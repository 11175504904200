.widget {
  display: grid;
  grid-template-columns: repeat(auto-fill, 336px);
  grid-column-gap: 16px;
  width: 100%;

  grid-row-gap: 16px;
}

@media (max-width: 1040px) {
  .widget {
    justify-content: center;
  }
}

@media (max-width: 800px) {
  .widget {
    padding: 16px 0px;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 140px);
  }
}
