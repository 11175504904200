.cards {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;
  margin-top: 80px;
}

.empty__wrapper {
  text-align: center;
}

.empty__text {
  margin-top: 8px;
}

@media (max-width: 572px) {
  .empty__text {
    margin-top: 4px;
  }
}
