@import '../../styles/colors.scss';

.paginagiotContainer {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 8px;
  list-style-type: none;
}

.liItem {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $black_main;
  min-width: 40px;
  height: 40px;
  padding: 8px 14px;
  border-radius: 8px;
  background: $white_main;
  transition: all 0.2s;
  cursor: pointer;
  &:hover {
    background: $filter_hover;
  }
  &_active {
    color: $white_main;
    background: $purple_main !important;
  }
}

.arrow {
  cursor: pointer;
  display: flex;
  min-width: 40px;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  background: $white_main;
  &:hover {
    background: $filter_hover;
  }
}

.prevArrow {
  margin-right: 8px;
  transform: rotate(180deg);
}

.nextArrow {
  margin-left: 8px;
}

@media (max-width: 1040px) {
  .liItem {
    &:hover {
      background: inherit;
    }
  }

  .arrow {
    &:hover {
      background: inherit;
    }
  }
}
