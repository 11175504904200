

.adConclusion__wrapper{
  display: flex;
  flex-direction: column;
  gap: 32px;
  &>*{
    width: fit-content;
  }
}
.btnWrapper {
  margin-top: 8px;
}

.form{
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.btnEditWrapper {
  margin-top: 40px;
  display: flex;
  gap: 24px;
}

.btn {
  width: 260px;
}

.errorMessageWrapper {
  margin-top: 40px;
}

@media (max-width: 700px) {
  .divider {
    margin: 40px auto;
  }

  .btnWrapper {
    width: 288px;
  }

  .btnEditWrapper {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .btn {
    width: 288px;
  }
}