@import '../../../../shared/styles/colors.scss';

.wrapper {
  position: relative;
  height: 56px;
  width: 100%;
  max-width: 160px;
  padding: 16px;
  border-radius: 8px;
  background-color: $purple_fill;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input {
  width: 32.5px;
  padding: 3px 0;
  background-color: $purple_fill;
  color: $black_main;
  text-align: center;

  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.label {
  position: absolute;
  user-select: none;
  transition: all 0.2s ease-in-out;
}

.error {
  display: inline;
  color: #ff0000;
  text-align: end;
  position: absolute;
  right: 0;
  bottom: -20px;
}

@media (max-width: 700px) {
  .wrapper {
    width: 288px;
  }

  .input {
    width: 96.5px;
    padding: 0 15px;
    font-size: 16px;
  }
}
