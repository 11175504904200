@import '../../styles/colors.scss';

.btn {
  display: flex;
  min-width: 128px;
  height: 40px;
  border-radius: 8px;
  padding: 8px 16px;
  color: $white_main;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  text-decoration: none;
  font-size: 16px;
  transition: 0.2s all;

  &:disabled {
    opacity: 0.2;
    cursor: inherit;
  }
}

.tonal {
  background: $purple_main;
  &:hover {
    background: $purple_main_hover;
  }
}
.outlined {
  color: $white_main;
}

.text {
  background: none;
}

.purple-main {
  &.tonal {
    color: $white_main;
    background: $purple_main;

    &:hover {
      background: $purple_main_hover;
    }

    &:disabled:hover {
      background: $purple_main;
    }
  }

  &.outlined {
    background: $white_main;
    border: 1px solid $purple_main;
    color: $purple_main;
    &:hover {
      border: 1px solid $purple_main_hover;
      color: $purple_main_hover;
      background: $purple_fill;
    }
  }

  &.text {
    color: $purple_main;
    &:hover {
      color: $purple_main_hover;
    }
  }
}

.black-main {
  &.tonal {
    background: $black_main;
    color: $white_main;
    &:hover {
      background: $secondary_black;
    }
  }

  &.outlined {
    color: $black_main;
    background: $white_main;
    border: 1px solid $black_main;
    &:hover {
      border: 1px solid $secondary_black;
      background: $purple_fill;
      color: $black_main;
    }
  }

  &.text {
    color: $black_main;
    &:hover {
      color: $black_main;
    }
  }
}

.secondary-black {
  &.tonal {
    background: $secondary_black;
    &:hover {
      background: $black_main;
    }
  }

  &.outlined {
    background: $white_main;
    border: 1px solid $secondary_black;
    &:hover {
      border: 1px solid $secondary_black;
      color: $secondary_black;
      background: $purple_fill;
    }
  }

  &.text {
    color: $secondary_black;
    &:hover {
      color: $black_main;
    }
  }
}

.grey {
  color: $grey;
  &:hover {
    color: $black_main;
  }
}

.button-red {
  &.tonal {
    color: $white_main;
    background: $button_red;

    &:hover {
      background: $purple_main_hover;
    }
  }

  &.outlined {
    background: $white_main;
    border: 1px solid $button_red;
    color: $button_red;
    &:hover {
      border: 1px solid $button_red_hover;
      background: rgba(255, 0, 0, 0.1);
      color: $button_red_hover;
    }
  }

  &.text {
    color: $button_red;
    &:hover {
      color: $button_red_hover;
    }
  }
}

.icons-fill {
  &.tonal {
    color: $purple_main;
    background: $icons_fill;

    &:hover {
      background: $icons_hover;
    }
  }

  &.outlined {
    background: $white_main;
    border: 1px solid $icons_fill;
    color: $purple_main;
    &:hover {
      border: 1px solid $icons_hover;
      background: $icons_fill;
      color: $purple_main_hover;
    }
  }

  &.text {
    color: $purple_main;
    &:hover {
      color: $purple_main_hover;
    }
  }
}

@media (max-width: 1040px) {
  .tonal {
    &:hover {
      background: $purple_main;
    }
  }

  .purple-main {
    &.tonal {
      &:hover {
        background: $purple_main;
      }
    }

    &.outlined {
      &:hover {
        border: 1px solid $purple_main;
        color: $purple_main;
        background: $white_main;
      }
    }

    &.text {
      &:hover {
        color: $purple_main;
      }
    }
  }

  .black-main {
    &.tonal {
      &:hover {
        background: $black_main;
      }
    }

    &.outlined {
      &:hover {
        color: $black_main;
        background: $white_main;
        border: 1px solid $black_main;
      }
    }

    &.text {
      color: $black_main;
      &:hover {
        color: $black_main;
      }
    }
  }

  .secondary-black {
    &.tonal {
      &:hover {
        background: $secondary_black;
      }
    }

    &.outlined {
      &:hover {
        border: 1px solid $secondary_black;
        color: inherit;
        background: $white_main;
      }
    }

    &.text {
      &:hover {
        color: $secondary_black;
      }
    }
  }

  .grey {
    &:hover {
      color: $grey;
    }
  }

  .button-red {
    &.tonal {
      &:hover {
        background: $button_red;
      }
    }

    &.outlined {
      &:hover {
        background: $white_main;
        border: 1px solid $button_red;
        color: $button_red;
      }
    }

    &.text {
      &:hover {
        color: $button_red;
      }
    }
  }

  .icons-fill {
    &.tonal {
      &:hover {
        background: $icons_fill;
      }
    }

    &.outlined {
      &:hover {
        background: $white_main;
        border: 1px solid $icons_fill;
        color: $purple_main;
      }
    }

    &.text {
      &:hover {
        color: $purple_main;
      }
    }
  }
}
