@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.purple__color {
  &::before {
    color: $purple_main;
  }
}

.telegram__color {
  &::before {
    color: #2aabee;
  }
}

.vk__color {
  &::before {
    color: #2787f5;
  }
}

.ok__color {
  &::before {
    color: #ff8800;
  }
}

.white__color {
  &::before {
    color: $white_main;
  }
}

.large {
  transform: scale(1.25);
}

.small {
  transform: scale(0.75);
}

.icon__wrapper {
  position: relative;
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  &_tonal {
    &::after {
      content: '';
      background: #f4f4f6;
      border-radius: 8px;
      bottom: 0;
      z-index: -1;
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.vk.icon__wrapper {
  &:hover {
    &::after {
      background: #2787f5;
    }

    .vk__color {
      &::before {
        color: $white_main;
      }
    }
  }
}

.ok.icon__wrapper {
  &:hover {
    &::after {
      background: #ff8800;
    }

    .ok__color {
      &::before {
        color: $white_main;
      }
    }
  }
}

.telegram.icon__wrapper {
  &:hover {
    &::after {
      background: #2aabee;
    }

    .telegram__color {
      &::before {
        color: $white_main;
      }
    }
  }
}
