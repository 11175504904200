.LeftColumn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.squarePhoto {
  width: 320px;
  height: 320px;
}

@media (max-width: 800px) {
  .squarePhoto {
    width: 240px;
    height: 240px;
  }
}
