@import '../../styles/colors.scss';

.overlay {
  background-color: rgba(4, 0, 19, 0.05);
  display: flex;
  justify-content: end;
  height: 100%;
  left: 0;
  overflow: scroll;
  position: fixed;
  top: 64px;
  width: 100%;
  z-index: 10;
  backdrop-filter: blur(5px);
  overflow: visible;
}

.container {
  padding: 30px;
  padding-bottom: 80px;
  min-width: 256px;
  max-width: 750px;
  background-color: $white_main;
  height: 100%;
  scroll-behavior: auto;
  overflow: scroll;
}
