@import-normalize;
@import '../../assets/fonts/stylesheet.css';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: 'Museo Sans Cyrl';
  transition: all 0.2s ease;
  border: 0;
  border-radius: 0;
}

#root {
  position: relative;
}
