@import '../../../../shared/styles/mixins.scss';
.plate {
	&__wrapper{
		margin-top: 4px;
	}
	padding: 12px 16px 12px 16px;
	border-radius: 16px;
	@include default_multiple_shadow;
	margin-bottom: 24px;
	position: relative;
}
.icon{
	width: 32px;
	img {
		width: 32px;
	}
}
.container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
@media (max-width: 1040px) {
	.container {
		gap: 10px;
	}
}
@media (max-width: 576px) {
	.container {
		gap: 8px;
	}
}