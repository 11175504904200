.wrapper {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.filters {
  max-width: 1040px;
  gap: 16px;
  // display: grid;
  // grid-template-columns: repeat(3, 336px);
  // grid-template-rows: 80px;
  display: flex;
  margin-bottom: 30px;
  flex-wrap: wrap;
  justify-content: space-between;

}


.triple_neighbours {
  padding: 8px 18px;
}

.selectFilter {
  width: 336px;
}

.mobileFiltersBtn {
  width: 100%;
  height: 48px;
}

@media (max-width: 1040px) {
  .filters {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 800px) {
  .filters {
    flex-direction: column;
  }
  .adaptiveFilter {
    width: 100%;
  }

  .triple_neighbours {
    padding: 8px 12px;
  }
  .selectFilter {
    width: 100%;
  }

  .course {
    padding: 8px 12px;
  }
  .adaptiveMultiple {
    width: 100%;
    justify-content: space-around;
  }
}

@media (max-width: 572px) {
  .filters {
    margin-top: 0;
  }
}
