@import '../../../shared/styles/colors.scss';
.more {
  display: flex;
  gap: 2px;
  align-items: center;
  cursor: pointer;
  &:hover {
    .more__text {
      color: $purple_main_hover;
    }
    .more_arrow {
      color: $purple_main_hover;
    }
  }
  &__arrow {
    color: $purple_main;
  }
  &__text {
    color: $purple_main;
  }
}
.wrapper {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
}
.reset {
  display: flex;
  align-items: center;
  gap: 12px;
  width: auto;
  cursor: pointer;
  &:hover {
    .reset__text {
      color: $black_main;
    }
  }
}
.btns {
  display: flex;
  gap: 40px;
  align-items: center;
}

.btn {
  width: 320px;
  height: 56px;
}
.arrow_up {
  transform: rotate(180deg);
  color: $purple_main;
  &:hover {
    color: $purple_main_hover;
  }
}

.x_svg {
  width: 10px;
  height: 11px;
}

.adaptiveWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

@media (max-width: 1040px) {
  .more {
    &:hover {
      .more__text {
        color: $purple_main;
      }
      .more_arrow {
        color: $purple_main;
      }
    }
  }
  .reset {
    display: flex;
    align-items: center;
    gap: 12px;
    width: auto;
    cursor: pointer;
    &:hover {
      .reset__text {
        color: $secondary_black;
      }
    }
  }
  .arrow_up {
    &:hover {
      color: $purple_main;
    }
  }
}

@media (max-width: 800px) {
  .wrapper {
    flex-direction: column;
    gap: 40px;
  }

  .btn {
    width: 100%;
    height: 48px;
  }
}
