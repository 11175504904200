@import '../../../../shared/styles/colors.scss';

.form {
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
}
.exampleText {
  width: 366px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: $example_grey;
  letter-spacing: -0.12px;
}
.textAreaWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.telegramInput {
  margin-top: 20px;
}
@media (max-width: 410px) {
  .exampleText {
    width: 100%;
  }
}
