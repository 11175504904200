@import '../../shared/styles/mixins.scss';
@import '../../shared//styles/colors.scss';

.registration_header {
	height: 88px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@include default_multiple_shadow;
	&__container{
		position: sticky;
		z-index: 10;
		top: 0;
		background: $white_main;
	}
	&__wrapper {
		padding: 20px 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	  }
	&__link {
		padding: 16px 0px;
		background: $purple_main;
		display: flex;
		gap: 40px;
		justify-content:center;
		align-items:center;
	}
	&__btn{
		display: flex;
		gap: 8px;
		align-items: center;
		&_wrapper{
			display: flex;
			align-items: center;
			gap: 20px;
		}
	}
	&__icon {
		background-color: #2AABEE;
	}
	&__title{
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;
		color: $white_main;
	}
	&__icon_wrapper{
		width: 24px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
	  }
}
.btn{
	&_slim{
		display: block;
		min-width: 64px;
		padding: 4px 8px;
	}
}
@media (max-width: 1040px) {
	.registration_header{
		&__link{
			flex-direction: column;
			gap: 8px;
			padding: 12px 0px;
		}
		&__title {
			order: 10;
		}
		&__btn{
			&_wrapper{
				gap: 5px;
			}
		}
	}
  }
@media (max-width: 450px) {
	.logo {
		height: 32px;
		width: 64px;
	}
	.btn{
		display: none;
		&_slim{
			display: block;
		}
	}
	
}