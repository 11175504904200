@import '../../../../shared/styles/colors.scss';

.switch {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 20px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
	opacity: 0;
	width: 0;
	height: 0;
  }
  
  /* The slider */
  .slider {
	position: absolute;
	cursor: pointer;
	width: 40px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $purple_fill;
	border: 1px solid #AAAAAA;
	-webkit-transition: .4s;
	transition: .4s;
  }
  
  .slider:before {
	position: absolute;
	content: "";
	height: 14px;
	width: 14px;
	left: 8%;
	bottom: 10%;
	background-color: #AAAAAA;
	-webkit-transition: .4s;
	transition: .4s;
  }
  
  input:checked + .slider {
	background-color: $purple_main;
	border: 1px solid $purple_main;
  }

  input:checked + .slider:before {
	-webkit-transform: translateX(130%);
	-ms-transform: translateX(130%);
	transform: translateX(130%);
	background-color: white;
  }
  
  /* Rounded sliders */
  .slider.round {
	border-radius: 34px;
  }
  
  .slider.round:before {
	border-radius: 50%;
  }