@import '../../../../shared/styles/colors.scss';

.avatar {
  position: relative;
  width: 366px;
  height: 200px;
  padding: 40px 29px 40px 29px;
  border-radius: 16px;
  border: 1px dashed var(--d-9-d-1-e-0-stroke, #cdbddb);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  align-items: center;
  input {
    opacity: 0;
    visibility: hidden;
    position: absolute;
  }
}
.error {
  display: inline;
  color: #ff0000;
  text-align: end;
  position: absolute;
  left: 117px;
  bottom: 58px;
}
.input {
  width: 144px;
  padding: 8px 40px;
  border-radius: 8px;
  background-color: $purple_main;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $white_main;
  cursor: pointer;
  &:hover {
    background: $purple_main_hover;
  }
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.imageWrap {
  border: 1px solid #ebebeb;
  border-radius: 16px;
  width: 196px;
  height: 196px;
  overflow: hidden;
  position: relative;
}
.remove {
  background-color: #fff;
  cursor: pointer;
  top: 12px;
  right: 12px;
  z-index: 9;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  width: 18px;
  height: 18px;

  img {
    width: 10px;
    height: 10px;
  }
}
@media (max-width: 410px) {
  .avatar {
    width: 100%;
    height: 310px !important;
  }
}
