.filters {
  margin-top: 64px;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, 336px);
  grid-template-rows: 80px 80px;
  margin-bottom: 30px;
}
.wrapper {
  display: flex;
  flex-direction: column;
}
.checkboxes {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.bathroomAndPlate {
  display: 'flex';
  gap: '40px';
}

.mobileFiltersBtn {
  width: 100%;
  height: 48px;
}

.minArendaPeriodItem {
  padding: 8px 24px;
}

.checkboxesWrapper {
}

@media (max-width: 1040px) {
  .filters {
    display: flex;
    flex-wrap: wrap;
  }

  .checkboxesWrapper {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 800px) {
  .filters {
    flex-direction: column;
  }

  .bathroomAndPlate {
    flex-wrap: wrap;
  }

  .adaptiveFilter {
    width: 100%;
  }

  .adaptiveMultiple {
    width: 100%;
    justify-content: space-around;
  }
}

@media (max-width: 572px) {
  .filters {
    margin-top: 0;
  }

  .minArendaPeriodItem {
    padding: 8px 14px;
  }
}
