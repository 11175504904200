@import '../../../shared/styles/colors.scss';
.info__wrapper {
  position: relative;
}

.card {
  margin-top: 40px;
}

.share {
  position: absolute;
  top: 16px;
  right: -24px;
  transform: translateX(100%);
}
@media (max-width: 1040px) {
  .info__wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .card {
    margin-top: 0px;
  }
}

@media (max-width: 800px) {
  .info__wrapper {
    background: $purple_fill;
    gap: 16px;
  }
}
