.wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 400;
}

.input {
  display: none;
}

.icon {
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  border: 2px solid #7953C3;
  background-color: #FFFFFF;
  margin-right: 8px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.active {
  background-color: #7953C3;
}