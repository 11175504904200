@import '../../../styles/colors';

.wrapper {
  min-height: 200px;
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  border: 1px dashed #CDBDDB;
}

.labelWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.title {
  font-size: 16px;
  letter-spacing: -0.16px;
  font-weight: 600;
}

.download {
  min-width: 150px;
  height: 40px;
  border-radius: 8px;
  padding: 8px 16px;
  color: $white_main;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  text-decoration: none;
  font-size: 16px;
  transition: 0.2s all;

  background: $purple_main;
  &:hover {
    background: $purple_main_hover;
  }
}

.imageWrap {
  border: 1px solid #EBEBEB;
  border-radius: 8px;
  width: 132px;
  height: 128px;
  overflow: hidden;
  position: relative;
}


.remove {
  background-color: #fff;
  cursor: pointer;
  top: 8px;
  right: 8px;
  z-index: 10;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  width: 18px;
  height: 18px;

  img {
    width: 10px;
    height: 10px;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.images {
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  max-width: 720px;
  width: 100%;
}

.error {
  display: inline;
  color: #FF0000;
  text-align: end;
  position: absolute;
  right: 0;
  bottom: -20px;
}

.text {
  text-align: center;
}


@media (max-width: 700px) {
  .wrapper {
    width: 288px;
  }

  .images {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
}