.text{
	padding-top: 24px;
}
.buttons{
	margin-top: 64px;
	display: flex;
	gap: 24px;
}
.modal_reg {
	padding: 40px 80px;
  }
  
  .text__decoration_no {
	text-decoration: none;
  }
@media (max-width: 476px) {
	.modal_reg {
	padding: 30px 40px ;
	}
	.buttons{
		margin-top: 30px;
		flex-direction: column;
		gap: 15px;
	}
	.btn_small{
		width: 100% !important;
	}
}