@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';

.purple__color {
  &::before {
    color: $purple_main;
  }
}

.black__color {
  &::before {
    color: $secondary_black;
  }
}

.large {
  transform: scale(1.25);
}

.small {
  transform: scale(0.75);
}
.tiny {
  transform: scale(0.5);
}

.noLink {
  text-decoration: none;
}

.telegram_icon{
  &::before {
    // color: #2AABEE !important;
  }
}

.icon__wrapper {
  position: relative;
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  transition: 0.2s all;
  &_tonal {
    span {
      &::before {
        position: relative;
        z-index: 1;
      }
    }
    &::after {
      content: '';
      background: $icons_fill;
      border-radius: 50px;
      bottom: 0;
      z-index: 0;
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
    }
    &:hover {
      &::after {
        background: $icons_hover;
      }

      .purple__color {
        &::before {
          color: $purple_main_hover;
        }
      }
    }
  }
  &_fill {
    &::after {
      content: '';
      background: $purple_main;
      border-radius: 50px;
      bottom: 0;
      z-index: -1;
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
    }
    &:hover {
      &::after {
        background: $purple_main_hover;
      }
    }
  }

  &_iconOnly {
    &:hover {
      .purple__color {
        &::before {
          color: $purple_main_hover;
        }
      }
      .black__color {
        &::before {
          color: $black_main;
        }
      }
    }
  }
}

@media (max-width: 1040px) {
  .icon__wrapper {
    &_tonal {
      &:hover {
        &::after {
          background: $icons_fill;
        }

        .purple__color {
          &::before {
            color: $purple_main;
          }
        }
      }
    }
    &_fill {
      &:hover {
        &::after {
          background: $purple_main;
        }
      }
    }

    &_iconOnly {
      &:hover {
        .purple__color {
          &::before {
            color: $purple_main;
          }
        }
        .black__color {
          &::before {
            color: $secondary_black;
          }
        }
      }
    }
  }
}
