$purple_main: #7953c3;
$purple_main_hover: #6b39cb;
$white_main: #ffffff;
$black_main: #040013;
$secondary_black: #5d5b66;
$grey: #aaaaaa;
$purple_fill: #f4f4f6;
$icons_fill: #f2eef9;
$icons_hover: #e9defc;
$button_green: #5dca37;
$button_red: #ff0000;
$button_red_hover: #cb0000;
$stroke: #d9d1e0;
$filter_hover: #eeeef1;
$button_grey: #cccccc;
$example_grey: #9e9e9e;
